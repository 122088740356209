.red-box{background-color: red !important; color:#FFF !important;}
.icon-t{display: flex; align-items: center;}
.ic {display: flex;margin-right: 5px;}
.first-col-w  td:nth-child(1), .first-col-w  th:nth-child(1){width: 210px;}
.first-col-w  td:nth-child(2), .first-col-w  th:nth-child(2){width: 250px;}
.child-table{padding: 0 !important;}
.h-750 {
    min-height: calc(100vh - 178px);
}
.scroll-table.h-700 tbody {
    max-height: calc(100vh - 340px);
}
.child-table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table-hover>tbody>tr:hover>* {
    --bs-table-accent-bg:unset !important;
   
}
