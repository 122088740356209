.modal-dialog.modal-md {
    max-width: 70%;
}
@media (max-width: 768px){
    .modal-dialog.modal-md {
        max-width: 90%;
    }
    .custom-font-size .ft-title.f-18.modal-title.h4 {
        font-size: 19px !important;
    }
    .ft-table-v.table th {
        font-size: 15px !important;
    }
    .big-font.f-18 {
        font-size: 18px !important;
        line-height: 1;
    }
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}
.max-w-620 .modal-dialog{max-width: 620px !important;}
.comment-form .form-control { width: 100%;
    height: auto;font-size: 14px !important;
}
.d-flex.inline-f{align-items: center;}
.d-flex.inline-f.mb-3 label {
    white-space: nowrap;
    width: 240px;
    display: block;
    text-align: right !important;
    padding-right: 15px;margin: 0;
}
.custom-cross-btn .btn-close {
    position: absolute;
    right: 20px;
    top: 50px;z-index: 1;
}
.max-w-900 .modal-dialog{max-width: 900px;}
.mt-minus-20{margin-top: -20px;}