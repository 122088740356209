/*
Name: 			main
Author: 		Sumit Sharma
Version: 		1.0.0
*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap'); */
:root {
  --cf-black-bg: #000;
  --cf-grey-bg: #f2f3f3;
  --cf-white-bg: #FFF;
  --cf-light-bg: #f9f9f9;
  --cf-orange-bg: #FFA800;
  --cf-green-bg: #28A745;
  --cf-blue-bg: #458FFF;


  --cf-color-white: #FFF;
  --cf-color-black: #000;
  --cf-color-orange: #F5720E;
  --cf-color-grey: #868686;
  --cf-orange-color: #FFA800;
  --cf-color-blue: #458FFF;
  --cf-color-green: #28A745;
  --cf-color-purple: #9E00FF;
  --cf-color-red: #ff0000;


  /* --cf-fp: 'Kanit', sans-serif;
       --cf-fs:  'Poppins', sans-serif; */
  --cf-fp: 'F37 Moon Demi';
  --cf-fs: 'Sailec';

  --cf-gap-150: 150px;
  --cf-gap-110: 110px;
  --cf-gap-100: 100px;
  --cf-gap-90: 90px;
  --cf-gap-80: 80px;
  --cf-gap-70: 70px;
  --cf-gap-60: 60px;
  --cf-gap-50: 50px;
  --cf-gap-40: 40px;
  --cf-gap-30: 30px;
  --cf-gap-20: 20px;

}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: var(--cf-fs);
  color: #404041;
  background: #eeeeee;
}

/* ----- change body color by sumit on 16may ---------------------- */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.primary-font {
  font-family: var(--cf-fp)
}

.secondary-font {
  font-family: var(--cf-fs)
}

a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 1400px) {
  .container {
    max-width: 96%;
  }
}

/* @media (min-width: 1900px){.container{ max-width: 1712px;}} */
@media (min-width: 1900px) {
  .container {
    max-width: 98%;
  }
}

@media (max-width: 1400px) {
  .container {
    max-width: 97%;
  }
}


h1 {
  font: normal normal 700 2.8rem/3.5rem 'F37 Moon Demi';
}

h2 {
  font: normal normal 700 2.5rem/3.2rem 'F37 Moon Demi';
}

h4 {
  font: normal normal bold 1.6rem/2rem 'F37 Moon Demi';
}

h3,
.h3 {
  font: normal normal bold 1.8rem/2rem 'F37 Moon Demi';
}

h5 {
  font: normal normal bold 1.1rem/2rem 'F37 Moon Demi';
}

h6 {
  font: normal normal bold 1.1rem/2.3rem 'F37 Moon Demi';
}

p {
  font: normal normal normal 1rem/1.4rem 'Sailec';
}

a:hover {
  color: var(--bs-link-hover-color);
  color: inherit;
}

.big-para {
  font: normal normal normal 1.4rem/2rem 'F37 Moon Demi';
}

.big-para-list {
  font: normal normal normal 1.2rem/1.8rem 'F37 Moon Demi';
}


.dark--bg {
  background-color: var(--cf-black-bg);
}

.white--bg {
  background-color: var(--cf-white-bg);
}

.dark--grey {
  background-color: var(--cf-grey-bg);
}

.light--grey {
  background-color: var(--cf-light-bg);
}

.orange-bg {
  background-color: var(--cf-orange-bg);
}

.white-bg {
  background-color: var(--cf-white-bg);
}

.ptb--110 {
  padding-top: var(--cf-gap-110);
  padding-bottom: var(--cf-gap-110);
}

.ptb--100 {
  padding-top: var(--cf-gap-100);
  padding-bottom: var(--cf-gap-100);
}

.ptb--90 {
  padding-top: var(--cf-gap-90);
  padding-bottom: var(--cf-gap-90);
}

.ptb--80 {
  padding-top: var(--cf-gap-80);
  padding-bottom: var(--cf-gap-80);
}

.ptb--70 {
  padding-top: var(--cf-gap-70);
  padding-bottom: var(--cf-gap-70);
}

.ptb--60 {
  padding-top: var(--cf-gap-60);
  padding-bottom: var(--cf-gap-60);
}

.ptb--50 {
  padding-top: var(--cf-gap-50);
  padding-bottom: var(--cf-gap-50);
}

.ptb--40 {
  padding-top: var(--cf-gap-40);
  padding-bottom: var(--cf-gap-40);
}

.ptb--30 {
  padding-top: var(--cf-gap-30);
  padding-bottom: var(--cf-gap-30);
}

.ptb--20 {
  padding-top: var(--cf-gap-20);
  padding-bottom: var(--cf-gap-20);
}

.mt--150 {
  margin-top: var(--cf-gap-150);
}

.my--minus-70 {
  margin-top: -70px;
  margin-bottom: -70px;
}

.mt--70 {
  margin-top: var(--cf-gap-70);
}

.mt--40 {
  margin-top: var(--cf-gap-40);
}


.color-white {
  color: var(--cf-color-white)
}

.color-black {
  color: var(--cf-color-black)
}

.color-orange {
  color: var(--cf-orange-color);
}

.color-torchred {
  color: #ff003c;
}

.color-blue {
  color: var(--cf-color-blue);
}

.blue-color {
  color: var(--cf-color-blue);
}

.color-green {
  color: var(--cf-color-green);
}

.color-purple {
  color: var(--cf-color-purple);
}

.color-red {
  color: var(--cf-color-red);
}

.color-yellow {
  color: var(--cf-color-orange);
}


.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-400 {
  font-weight: 400 !important;
}


.f-40 {
  font-size: 26px;
}

.f-35 {
  font-size: 22px;
}

.f-30 {
  font-size: 20px;
}

.f-25 {
  font-size: 18px;
}

.f-20 {
  font-size: 17px;
}

.f-19 {
  font-size: 16px;
}

.f-18 {
  font-size: 16px;
}

.f-16 {
  font-size: 15px;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

/* .header-bar .border-right::after {
      content: "";
      position: absolute;
      right: 8%;
      width: 2px;
      height: 30px;
      background: #5a5a5a;
      top: -2px;
  } */

.custom-font-size .f-18.modal-title.h4 {
  font-size: 18px;
}

.custom-font-size h4 {
  font-size: 18px;
}

.custom-font-size p {
  font-size: 16px;
}

.custom-font-size .custom-select {
  font-size: fixed !important;
}

.top-bar {
  background: #FFF;
  padding: 10px 0px;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
}

.top-bar .logos img {
  height: 40px
}

.top-bar .logos img.h-26 {
  height: 26px;
}

span.name {
  display: inline-block;

  color: #FFF;
  font-weight: 500;
  background: #63BC62;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
}

.bar-bottom-details ul {
  padding-left: 15px;
  font-size: 12px;
  line-height: 20px;
  columns: 2;
  margin-bottom: 0px;
}

.bar-bottom-details.green ul li::marker {
  color: #28A745;
}

.bar-bottom-details.orange ul li::marker {
  color: #FFA800;
}

.bar-bottom-details.purple ul li::marker {
  color: #9C06FE;
}

.bar-bottom-details.blue ul li::marker {
  color: #28A745;
}

.bar-bottom-details {
  position: absolute;
  width: calc(100% + 2px);
  background: #FFF;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 10px;
  border: 1px solid #CEE2FE;
  z-index: 9999;
  display: none;
  left: -1px;
  border-top: 0;
}

.box-h:hover .bar-bottom-details {
  display: block;
}

.box-h {
  padding: 15px 8px;
  border: 1px solid transparent;
  background: transparent;
  border-bottom: 0px !important;
}

.box-h:hover {
  /* padding: 5px 10px; */
  /* border: 1px solid #CEE2FE; */
  background: #FFF;
  /* border-radius: 10px 10px 0px 0px; */
  border-bottom: 0px !important;
}

.box-h:hover::after {
  display: none;
}

.box-h:hover .fa {
  opacity: 0
}

.col-bg {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
}


/* .shadow-box { box-shadow: -8px 12px 18px 0px #E9EFFE;  border-radius: 10px; padding: 10px 15px;border: 1px solid #DEEBFD;} */
.shadow-box {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
}

/* ----- change Shadow Box css by sumit on 16may ---------------------- */
.pipe {
  color: #A3A3A3;
  font-weight: 100;
}

.fields .form-control {
  height: 35px;
}

.mt-minus-25 {
  margin-top: -20px;
}

.search-box {
  background-color: #eee !important;
  border-radius: 5px;
  border: 1px solid #828282 !important;
  box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
  color: #4B4D4D;
  height: 35px;
}

.search-box::-webkit-input-placeholder {
  color: #4B4D4D !important;
  font-size: 12px !important;
}

.search-box:-ms-input-placeholder {
  color: #4B4D4D !important;
  font-size: 12px !important;
}

.search-box::placeholder {
  color: #4B4D4D !important;
  font-size: 12px !important;
}

.nav-pills li button {
  color: #333;
  width: auto !important;
  text-shadow: none !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 8px 10px !important;
  border: 1px solid #E1E1E1 !important;
  background: transparent !important;
  border-radius: 8px !important;
}
.timeline .nav-pills li button {
  /* color: #FFF !important; */
}

.nav-pills li button.active {
  background: #0E8BFF !important;
  color: #FFF !important;
}

.nav-pills li button.active img {
  filter: invert(1);
  width: auto;
}

.round-border {
  border: 1px solid #D9D9D9;
  border-radius: 18px;
  padding: 10px 15px;
}

.round-border p {
  margin: 0px;
}

.alert {
  padding: 10px 15px;
  border-radius: 18px;
  margin: 0;
}

.progress {
  width: 80%;
  height: 6px;
  border-radius: 5px;
}

.border-right::after {
  content: "";
  position: absolute;
  background-color: #A3A3A3;
  right: 0;
  width: 1px;
  height: 70%;
}

.ml-e>div+div {
  margin-left: 10px;
}

.border-right::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 40px;
  background: #A3A3A3;
  top: 25%;
}

.shadow-box .ui-shadow-inset {
  box-shadow: none !important;
  border: 0 !important;
  background: transparent !important;
}

.ui-content {
  padding: 0 !important;
}

.timeline .nav-pills li button.active {
  background: #28A745 !important;
}


/* =====================Date============================== */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.fields .form-control {
  height: 15px;
  padding: 0;
}

.chrt {
  margin-top: -15px;
}

.h-250 {
  height: 220px;
}

/* =================Range Slider============================ */
html>body .ui-rangeslider .ui-rangeslider-sliders .ui-slider-track:first-child,
.ui-slider-track {
  height: 8px !important;
}

.ui-slider-track {
  box-shadow: none !important;
  background-color: #E4E4E4 !important;
  border-color: #E4E4E4 !important;
}

.ui-slider-track .ui-btn.ui-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #28A745;
  border: 0px;
  margin: -12px 0 0 -15px !important;
}

.ui-slider-bg.ui-btn-active {
  background: linear-gradient(90deg, #28A745 15.54%, #A8F74D 100%);
}

.ui-page-theme-a .ui-slider-handle:hover {
  background: #28A745 !important;
}

.ui-slider-handle:nth-child(3) {
  background: #A8F74D !important;
}

.ui-rangeslider .ui-slider-track:first-child .ui-slider-bg {
  display: block !important;
}

/* =============================================== */

.i img {
  height: 30px;
  width: auto;
}

.plr-4 {
  padding-left: 4px;
  padding-right: 4px;
  ;
}

.ui-input-text,
.ui-input-search {
  margin: 0 0 !important;
}

.ui-mobile .ui-page-active {
  overflow: hidden !important;
}

.db-grid .col-md-3 {
  margin-bottom: 0px;
}

.db-grid {
  max-height: calc(100vh - 260px);
  overflow: auto;
  /* min-height: 580px; */
  /* min-height: calc(100vh - 260px); */
  margin-bottom: 0.25rem;
}

@media screen and (min-height: 1200px) {
  .db-grid {
    max-height: 100% !important;
  }
}

@media (min-width: 768px) {
  .db-grid>.col-md-2 {
    width: 20%;
  }
}

.db-grid::-webkit-scrollbar {
  width: 5px;
}

.db-grid::-webkit-scrollbar-track {
  background: #DEEBFD;
  border-radius: 30px;
}

.db-grid::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}
.scrollbar::-webkit-scrollbar {
  width: 5px; height: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #DEEBFD;
  border-radius: 30px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}
.overflow-auto::-webkit-scrollbar {
  width: 5px;height: 5px;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #DEEBFD;
  border-radius: 30px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}

/* ============================================ */
.av-btn {
  text-shadow: none;
  cursor: pointer;
  color: #FFF !important;
  font-weight: 400 !important;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
}

.green-btn {
  background: #5E8E28;
}

.blue-btn {
  background: #458FFF
}

.purple-btn {
  background: #9E00FF
}

.small-input .ui-input-text {
  width: auto;
  display: inline-block;
}

.small-input .ui-input-text .form-control {
  height: 25px !important;
  width: 40px;
  border: 1px solid #6D6D6D;
  min-height: auto;
  text-align: center;
  line-height: 25px !important;
  font-size: 14px;
}

.h-line {
  height: 160px;
  background: #d1d1d1;
  width: 1px;
  margin-top: 20px;
  margin: 0 auto;
  margin-top: 20px;
}

.form-control[readonly] {
  pointer-events: none;
}

#update {
  display: none;
}

.form-check-input[type=checkbox] {
  display: none;
}

label.form-check-label.ui-btn {
  padding: 4px 10px;
  padding-left: 2.5em;
}

button.ui-btn {
  width: auto;
}

button.ui-btn {
  width: auto !important;
  border: 0;
  box-shadow: none;
}

.submit-btn {
  background: #458FFF !important;
  text-shadow: none !important;
  color: #FFF !important;
  text-transform: capitalize;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

textarea {
  text-shadow: none !important;
  box-shadow: none !important;
}

.apexcharts-legend-series {
  display: block !important;
  width: 100%;
}


.redborder {
  border: 1px solid #FF4560
}

.textdanger {
  color: #FF4560;
  font-weight: 700;
}

/* ======================================Fonts========================================================================== */
@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec-Black.woff2') format('woff2'),
    url('./assets/fonts/Sailec-Black.woff') format('woff'),
    url('./assets/fonts/Sailec-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec Hairline';
  src: url('./assets/fonts/SailecHairline.woff2') format('woff2'),
    url('./assets/fonts/SailecHairline.woff') format('woff'),
    url('./assets/fonts/SailecHairline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec-Bold.woff2') format('woff2'),
    url('./assets/fonts/Sailec-Bold.woff') format('woff'),
    url('./assets/fonts/Sailec-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec-Light.woff2') format('woff2'),
    url('./assets/fonts/Sailec-Light.woff') format('woff'),
    url('./assets/fonts/Sailec-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec-Medium.woff2') format('woff2'),
    url('./assets/fonts/Sailec-Medium.woff') format('woff'),
    url('./assets/fonts/Sailec-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec.woff2') format('woff2'),
    url('./assets/fonts/Sailec.woff') format('woff'),
    url('./assets/fonts/Sailec.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sailec';
  src: url('./assets/fonts/Sailec-Thin.woff2') format('woff2'),
    url('./assets/fonts/Sailec-Thin.woff') format('woff'),
    url('./assets/fonts/Sailec-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon';
  src: url('./assets/fonts/F37Moon-Black.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Black.woff') format('woff'),
    url('./assets/fonts/F37Moon-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon';
  src: url('./assets/fonts/F37Moon-Bold.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Bold.woff') format('woff'),
    url('./assets/fonts/F37Moon-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon Demi';
  src: url('./assets/fonts/F37Moon-Demi.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Demi.woff') format('woff'),
    url('./assets/fonts/F37Moon-Demi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon';
  src: url('./assets/fonts/F37Moon-Light.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Light.woff') format('woff'),
    url('./assets/fonts/F37Moon-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon';
  src: url('./assets/fonts/F37Moon-Regular.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Regular.woff') format('woff'),
    url('./assets/fonts/F37Moon-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'F37 Moon';
  src: url('./assets/fonts/F37Moon-Thin.woff2') format('woff2'),
    url('./assets/fonts/F37Moon-Thin.woff') format('woff'),
    url('./assets/fonts/F37Moon-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

.db-grid .ag-chart-wrapper {
  max-height: 250px;
  cursor: pointer !important;

}

.mb-minus-10 {
  margin-bottom: -15px;
}

.width-100p {
  width: 55px;
}

td.f-row.minheight60 {
  word-break: break-word;
  text-align: left !important;
  padding-right: 3px;
}

.new-table-design.spacing-table td:nth-child(1),
.new-table-design.spacing-table th:nth-child(1) {
  width: 75px;
  min-width: 75px;
} 


.new-table-design.spacing-table td:nth-child(2),
.new-table-design.spacing-table th:nth-child(2) {
  width: 135px;
  min-width: 135px;
}

.new-table-design.spacing-table td:nth-child(3),
.new-table-design.spacing-table th:nth-child(3) {
  width: 75px;
  min-width: 75px;
}

.new-table-design.spacing-table td:nth-child(4),
.new-table-design.spacing-table th:nth-child(4) {
  width: 90px;
  min-width: 90px;
}

.new-table-design.spacing-table td:nth-child(5),
.new-table-design.spacing-table th:nth-child(5) {
  width: 90px;
  min-width: 90px;
}

.new-table-design.spacing-table td:nth-child(6),
.new-table-design.spacing-table th:nth-child(6) {
  width: 73px;
  min-width: 73px;
}

.new-table-design.spacing-table td:nth-child(7),
.new-table-design.spacing-table th:nth-child(7) {
  min-width: 170px;
}
.new-table-design.spacing-table th.coll {
  white-space: normal;
}
.db-grid .shadow-box {
  overflow: hidden;
}

.twopx {
  width: calc(100% - 185px);
  margin-left: -5px;
}

.details .twopx {
  width: calc(100% + 40px);
  /* margin-left: -20px; */
  /* background-color: red; */
}

.modal .sidebar--close {
  display: none;
}

.modal .width-in {
  width: 100% !important;
  margin: 0 !important;
  padding: 15px 15px !important;
  margin-left: 7px !important;overflow-y: hidden !important;
}

.modal .copyright-footer {
  display: none;
}

.bg-transparent.btn-close {
  /* background: transparent; */
  outline: none;
}

.select-style.css-b62m3t-container {
  outline: none;
  /* border: 1px solid gray; */
  border-radius: 5px;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background: transparent !important;
  height: 35px !important;
  min-height: 35px !important;

  border-color: #828282 !important;
  border-radius: 5px !important;

  font-size: 12px !important;
}

.hIgppe,
.kpaCiq {
  background-color: #28A745 !important;
  padding: 0px 15px !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 35px !important;
  line-height: 35px !important;
}

.timeline a {
  margin-top: 0.9rem;
  margin-left: 1rem;
}

.top-bar .timeline a {
  margin-top: 0;
  margin-left: 0px;
  margin-right: 15px;
}

.select-style.css-b62m3t-container {
  font-size: 12px !important;
}

.hIgppe:hover,
.kpaCiq:hover {
  background-color: #FFF !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 6px !important;
}
.css-t3ipsp-control {
  background-color: transparent !important;
}
.css-1nmdiq5-menu {
  overflow-x: hidden !important;
  /* width: 70px !important; */
  /* overflow-y: scroll !important; */
}
.form-select:disabled {
  background-color: #e3e3e3 !important;
  border: 1px solid #D9D9D9 !important;
}
.apexcharts-legend.apx-legend-position-bottom,
.apexcharts-legend.apx-legend-position-top {
  flex-wrap: nowrap !important;
}
.custom-close-button {
  color: #ff0000;
  /* Red color */
  border: none;
  width: 30px;
  /* Set the desired width and height */
  height: 30px;
  font-size: 20px;
  /* Adjust the font size as needed */
  line-height: 1;
  /* Center the icon vertically */
  cursor: pointer;
  background: transparent;

}
.width-in .custom-close-button {
  height: auto;
}
.custom-close-button:hover {
  color: #cc0000;
}
.arrow_box {
  padding: 5px;
}
.modal-content .custom-font-size h4 {
  display: flex;
  align-items: center;
}
.modal-content .custom-font-size h4 img {
  width: 18px;
  margin-right: 7px;
}
.selected-active-row {
  background-color: rgba(0, 0, 0, 0.075);
}
tbody.crossi tr td {
  position: relative;
}
tbody.crossi tr td .custom-pos {
  position: absolute;
  margin-left: 80px;
}
.sumary-text {
  font-size: 13px !important;
  margin-top: 5px;
  white-space: nowrap;
}
table thead tr th .ms-1 img {
  width: 8px;
  vertical-align: baseline;
}
.realtime-details {
  padding: 0;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  list-style: none;
  line-height: 3.2;
  border-left: 1px solid #bdbdbd;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 25px;
}

.realtime-details li {
  display: flex;
  justify-content: space-around;
}

.modelcurrprev .twopx {
  width: calc(100% - 0px);
}

.width-160 {
  width: 185px
}

.css-1hb7zxy-IndicatorsContainer {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

.form-control:focus {
  box-shadow: none !important;
}

.header-bar .col .css-b62m3t-container .css-13cymwt-control,
.header-bar .col .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  height: 60px !important;
  font-size: 16px !important;
}

.header-bar.overt .col .css-b62m3t-container .css-13cymwt-control,
.header-bar.overt .col .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  height: auto !important;
  font-size: 14px !important;
}

.header-bar.h-45p .col .css-b62m3t-container .css-13cymwt-control,
.header-bar.h-45p .col .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  height: 45px !important;
  font-size: 14px !important;
}

.css-b62m3t-container .css-13cymwt-control,
.css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
}

.border-less.css-b62m3t-container .css-13cymwt-control,
.border-less .css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  border: 0px !important;
}

.border-less .css-13cymwt-control,
.border-less .css-t3ipsp-control {
  background: transparent !important;
  height: 35px !important;
  min-height: 35px !important;
  border-color: #828282 !important;
  border-radius: 5px !important;
  font-size: 18px !important;
}

.border-less .css-1u9des2-indicatorSeparator {
  display: none;
}

.border-less.custom-select {
  width: auto;
  display: inline-block;
  border: 0px !important;
  font: normal normal bold 1rem/1rem 'F37 Moon Demi' !important;
  padding: 0 5px 0 5px;
  background-repeat: no-repeat !important;
  background-position: 100% 50% !important;

}

span.realTimenumber {
  min-width: 42px;
  display: inline-block;
  text-align: right;
}

.dropdownwithsearch>.input-group>span {
  position: absolute;
  right: 43px;
  top: 4px;
}

.witoutTitle {
  border: 0px;
}

.modal-header.witoutTitle .btn-close {
  margin-top: -5px !important;
  z-index: 1;
}

.height-0 {
  height: 0px;
}

.modal-header.witoutTitle.height-0 .btn-close {
  margin-top: 27px !important;
  margin-right: 10px !important;
}

.realtime-details li {
  cursor: pointer;
}

.top-bar .profile-cover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #28A745;
}

.profile_picture .profile_pic>img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #28A745;
}

.profile_picture .profile_pic input#pic {
  display: none;
}

.profile_picture .profile_pic img.prof_img {
  position: absolute;
  right: 7px;
  bottom: 0px;
}

.real-validation {
  color: red;
}

.dropdown-toggle::after {
  display: none;
}

.modal-dialog .custom-font-size .custom-select {
  font-size: 17px !important;
}

.h-85 {
  min-height: 85px;
}

.bottom-text {
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.horizontal-line {

  width: 1.5px;
  height: 23px;
}

.mt-minus-15 {
  margin-top: -8px;
}

.min-w-45 {
  min-width: 45px;
  display: inline-block;
}

.ht {
  height: 2.3em;
  line-height: 2.2857142857142856em;
}

.red-c {
  color: #e75450;
}

.green-c {
  color: #28a745;
}

.h-650 {
  min-height: calc(100vh - 240px);
}

.h-580 {
  min-height: calc(100vh - 327px);
}

.h-610 {
  min-height: calc(100vh - 300px);
}

.scroll-table tbody.h-422 {
  max-height: calc(100vh - 422px);
}

.h-650 .scroll-table tbody {
  display: block;
  /* max-height: 555px; */
  max-height: calc(100vh - 350px);
}

small.cursor-pointer.border-btn.f-12.green-b {
  /* font-size: 12px !important; */
  padding: 6px 10px;
}

.font-w-500 {
  font-weight: 500;
}

.rt-v button {
  line-height: 1;
}

.rt-v .next {
  right: -25px;
  padding: 3px;
  background-color: white;
  box-shadow: -2px 1px 3px #ccc;
  z-index: 9;
  border-radius: 50%;
}

.rt-v .prev {
  left: -25px;
  padding: 3px;
  z-index: 9;
  background-color: white;
  box-shadow: 1px 2px 3px #ccc;
  border-radius: 50%;
}

.overlfow-auto {
  overflow: auto;
}

.biwekkly-table tr th:nth-child(1),
.biwekkly-table tr td:nth-child(1) {
  width: 120px !important;
}

.biwekkly-table tr th:nth-child(2),
.biwekkly-table tr td:nth-child(2) {
  width: 80px !important;
}

.biwekkly-table tr th:nth-child(3),
.biwekkly-table tr td:nth-child(3) {
  width: 110px !important;
}

.biwekkly-table tr th:nth-child(4),
.biwekkly-table tr td:nth-child(4) {
  width: 110px !important;
}

.biwekkly-table tr th:nth-child(5),
.biwekkly-table tr td:nth-child(5) {
  width: 100px !important;
}

.biwekkly-table tr th:nth-child(6),
.biwekkly-table tr td:nth-child(6) {
  width: 100px !important;
}

.rt-v .next img {
  width: 23px;
}

.rt-v .prev img {
  width: 23px;
}

.height-417 {
  min-height: 330px;
}

.min-height-440 {
  min-height: 440px;
}

.min-height-400 {
  min-height: 400px;
}

.h-25p .apexcharts-canvas {
  margin-top: -10px;
}


.swal2-container {
  z-index: 20000 !important;
}

.h-720 {
  min-height: calc(100vh - 190px);
}

.h-695 {
  min-height: calc(100vh - 240px);
}

.custom-select-v.cap button {

  /* font-weight: 600; */
  text-transform: uppercase;
}

.filter-btn .ft-btn {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 3px 8px;
  border-radius: 4px;
}

.filter-btn .ft-btn.active {
  background: #3481be;
}

.bg-grey {
  background: #454548;
  padding: 2px 15px;
}

.modal-content table.f-16 td {
  font-size: 14px !important;
}

.h-600 {
  max-height: calc(100vh - 310px);
}

.modal-header.cros-position .btn-close {
  position: absolute;
  right: 15px;
  top: 50px;
}

.cros-position.modal-header {
  padding-right: 40px;
}

.sidenav {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.sidenav::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sidenav::-webkit-scrollbar-track {
  border-radius: 30px;
  background: #a7c6e4;
}

.sidenav::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #a7c6e4;
}
#mySidenav::-webkit-scrollbar,
.css-1n6sfyn-MenuList::-webkit-scrollbar,
.css-qr46ko::-webkit-scrollbar
{
  width: 5px;
  height: 5px;
}
#mySidenav::-webkit-scrollbar-track,
.css-1n6sfyn-MenuList::-webkit-scrollbar-track,
.css-qr46kot::-webkit-scrollbar-track
{
  border-radius: 30px;
  background: #DEEBFD;
}
#mySidenav::-webkit-scrollbar-thumb,
.css-1n6sfyn-MenuList::-webkit-scrollbar-thumb,
.css-qr46ko::-webkit-scrollbar-thumb
{
  border-radius: 30px;
  background: #a7c6e4;
}
.schdule-summerytable tr th:nth-child(2),
.schdule-summerytable tr td:nth-child(2) {
  width: 100px !important;
}
.schdule-summerytable tr th:nth-child(3),
.schdule-summerytable tr td:nth-child(3) {
  width: 110px !important;
}
.schdule-summerytable tr th:nth-child(4),
.schdule-summerytable tr td:nth-child(4) {
  width: 100px !important;
}
.schdule-summerytable tr th:nth-child(5),
.schdule-summerytable tr td:nth-child(5) {
  width: 110px !important;
}
.border-light {
  border: 1px solid #828282 !important;
}

.mmb-summery-table tr th:nth-child(2),
.mmb-summery-table tr td:nth-child(2) {
  width: 110px !important;
}

.mmb-summery-table tr th:nth-child(3),
.mmb-summery-table tr td:nth-child(3) {
  width: 120px !important;
}
.mmb-summery-table tr th:nth-child(4),
.mmb-summery-table tr td:nth-child(4) {
  width: 110px !important;
}
.agency-summery-table tr th:nth-child(2),
.agency-summery-table tr td:nth-child(2) {
  width: 150px !important;
}
.agency-summery-table tr th:nth-child(3),
.agency-summery-table tr td:nth-child(3) {
  width: 110px !important;
}
/* ============Responsive Css======================= */
@media (max-width: 1365px) {
  .min-w-1100 {
    min-width: 750px;
  }
}
@media only screen and (min-device-width: 1441px) and (max-device-width: 1920px) {
  .width-in {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* @media only screen and (min-device-width: 1300px) and (max-device-width: 1440px) { */
  @media (min-width: 1300px) and (max-width: 1440px){
  .modal-dialog {
      max-width: 97%;
  }

  .h-610 {
    min-height: calc(100vh - 285px);
  }
  .fs-16 {
    font-size: 15px;
  }
  .biwekkly-table tr th:nth-child(1),
  .biwekkly-table tr td:nth-child(1) {
    width: 100px !important;
  }

  .biwekkly-table tr th:nth-child(2),
  .biwekkly-table tr td:nth-child(2) {
    width: 70px !important;
  }

  .biwekkly-table tr th:nth-child(3),
  .biwekkly-table tr td:nth-child(3) {
    width: 90px !important;
  }

  .biwekkly-table tr th:nth-child(4),
  .biwekkly-table tr td:nth-child(4) {
    width: 90px !important;
  }

  .biwekkly-table tr th:nth-child(5),
  .biwekkly-table tr td:nth-child(5) {
    width: 90px !important;
  }

  .biwekkly-table tr th:nth-child(6),
  .biwekkly-table tr td:nth-child(6) {
    width: 90px !important;
  }

  .h-660 {
    min-height: calc(100vh - 265px);
  }

  .f-40 {
    font-size: 20px;
  }

  .i img {
    height: 22px;
  }

  .f-16 {
    font-size: 13px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 15px;
  }

  .f-35 {
    font-size: 18px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  /* .m-f14 {
    font-size: 13px;
  } */

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Sailec';
    margin-bottom: 8px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 12px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: calc(100vh - 260px);
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 10px !important;
  }

  .modal-content table td {
    font-size: 10px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .tooltip {
    font-size: 12px;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }

  .h-650 {
    min-height: calc(100vh - 225px);
  }

  .scroll-table tbody.h-472 {
    max-height: calc(100vh - 450px);
  }

  .activedailymanagement-s.hlf .col-md-6 {
    width: 60%;
  }

  .box-heading {
    font-size: 16px;
  }

  th.coll,
  .transparent-btn {
    font-size: 12px;
  }

  th.coll {
    font-size: 13px;
  }

  .header-bar .col .css-b62m3t-container .css-13cymwt-control,
  .header-bar .col .css-t3ipsp-control {
    outline: none !important;
    box-shadow: none !important;
    height: 55px !important;
    font-size: 16px !important;
  }

  .width-in {
    overflow: auto;
  }
  .realtime-page.width-in.overflow-auto{
    overflow-x: auto;
    overflow-y: hidden !important;
  }
  .width-in::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .width-in::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
  }

  .width-in::-webkit-scrollbar-thumb {
    background: #a7c6e4;
    border-radius: 30px;
  }

  .sidebar--close ul li a span {
    font-size: 12px;
  }

  .first-t td:nth-child(1),
  .first-t th:nth-child(1) {
    width: 270px;
  }

  .ind-table th,
  .activity-tabel td {
    font-size: 13px;
  }

  .h3,
  h3 {
    font: normal normal 700 1.4rem/2rem F37 Moon Demi !important;
  }

 
}
/* @media only screen and (min-device-width: 1153px) and (max-device-width: 1299px) { */
  @media (min-width: 1153px) and (max-width: 1299px){
   .modal-dialog {
      max-width: 97%;
  }
  .h-610 {
    min-height: calc(100vh - 280px);
  }

  .sidebar--close ul li a span {
    font-size: 12px;
  }

  .first-t td:nth-child(1),
  .first-t th:nth-child(1) {
    width: 270px;
  }

  .ind-table th,
  .activity-tabel td {
    font-size: 13px;
  }

  /* .activedailymanagement-s.hlf .col-md-6 {width: 60%;} */
  .h-660 {
    min-height: calc(100vh - 265px);
  }

  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 13px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Sailec';
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    /* max-height: 480px; */
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 12px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }

  /* .db-grid {
    min-height: calc(100vh - 250px);
  } */
  .scroll-table tbody.h-472 {
    max-height: calc(100vh - 450px);
    min-height: 380px;
  }

  th.coll {
    font-size: 13px;
  }

  .header-bar .col .css-b62m3t-container .css-13cymwt-control,
  .header-bar .col .css-t3ipsp-control {
    outline: none !important;
    box-shadow: none !important;
    height: 55px !important;
    font-size: 16px !important;
  }

  .overtime-table td:nth-child(2),
  .overtime-table th:nth-child(2) {
    width: 130px;
    min-width: 130px;
  }

  .overtime-table td:nth-child(3),
  .overtime-table th:nth-child(3) {
    width: 110px;
    min-width: 110px;
  }

  .overtime-table td:nth-child(4),
  .overtime-table th:nth-child(4) {
    width: 130px;
    min-width: 130px;
  }

  span.width-140,
  span.tx {
    font-size: 14px;
  }

  h3,
  .h3 {
    font: normal normal bold 1.3rem/1.6rem 'F37 Moon Demi';
  }

  .h-454 {
    min-height: calc(100vh - 305px);
  }
  .scroll-table.long-table tbody {
    max-height: calc(100vh - 410px);
    /* max-height: 615px; */
}
.min-h7 {
  min-height: calc(69vh + 3px) !important;
}
.scroll-table.small-scroll tbody {
  /* max-height: 270px; */
  max-height: calc(49vh - 251px) !important;
}
.h-695 {
  min-height: calc(100vh - 235px);
}
.schdule-summerytable tr th:nth-child(2), .schdule-summerytable tr td:nth-child(2) {
  width: 75px !important;
}
.schdule-summerytable tr th:nth-child(3), .schdule-summerytable tr td:nth-child(3) {
  width: 95px !important;
}
.schdule-summerytable tr th:nth-child(4), .schdule-summerytable tr td:nth-child(4) {
  width: 90px !important;
}
.schdule-summerytable tr th:nth-child(5), .schdule-summerytable tr td:nth-child(5) {
  width: 100px !important;
}



}

@media (max-width: 1919px) and (min-width: 767px) {

  .modal-body .form-control {
    width: 62px;
    font-size: 11px !important;
  }
  .comment-form .form-control {
    width: 100% !important;
  }
  .modal-body .t {
    font-size: 13px;
    padding-right: 5px !important;
}
td.img img {
  width: 30px;
  padding: 2px;
}
}
/* @media (max-width: 1441px) and (min-width: 767px) */
/* @media only screen and (min-device-width: 767px) and (max-device-width: 1441px) { */
@media (max-width: 1441px) and (min-width: 767px) {

  .modal-dialog {
    max-width: 97%;
}
  .width-in {
    width: calc(100% - 150px);
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-body .form-control {
    width: 62px;
    font-size: 11px !important;
  }
}

/* @media (max-width: 2000px) and (min-width: 1799px) */
@media only screen and (min-device-width: 1799px) and (max-device-width: 2000px)
{

  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 210px;
  }
}
/* @media (max-width: 1850px) and (min-width: 1700px)  */
@media only screen and (min-device-width: 1700px) and (max-device-width: 1850px)
{

  /* .ww2.col-md-2 {
    display: none;
  } */
  .w-58 {
    width: calc(58% - 70px);
  }

  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 140px;
    margin-left: 7px !important;
    margin-right: 7px !important;
  }



  .width-in {
    padding-left: 30px;
    padding-right: 30px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 210px;
  }

  .rt-v .next {
    right: -30px;
  }

  .rt-v .prev {
    left: -30px;
  }
}
@media (min-width:1600px) {
  .ft-table-v.table td {
    font-size: 22px !important;
  }

}
/* @media (max-width: 1699px) and (min-width: 1600px)  */
@media only screen and (min-device-width: 1600px) and (max-device-width: 1699px)
{
  /* .ww2.col-md-2 {
    display: none;
  } */


  .w-58 {
    width: calc(58% - 70px);
  }

  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 140px;
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .width-in {
    padding-left: 30px;
    padding-right: 30px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 200px;
  }

  .rt-v .next {
    right: -30px;
  }

  .rt-v .prev {
    left: -30px;
  }
}
/* @media (max-width: 1599px) and (min-width: 1441px) */
@media only screen and (min-device-width: 1441px) and (max-device-width: 1599px)
{
  /* .ww2.col-md-2 {
    display: none;
  } */

  /* .w-58 {
    width: 68%;
  } */
  .w-58 {
    width: calc(58% - 70px);
  }

  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 130px;
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .width-in {
    padding-left: 30px;
    padding-right: 30px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 190px;
  }

  .row.db-grid .col-md-3 {
    width: 33.33%;
  }

  .rt-v .next {
    right: -30px;
  }

  .rt-v .prev {
    left: -30px;
  }
}
/* @media (max-width: 1440px) and (min-width: 768px)  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1440px)
{

  .w-58 {
    width: calc(58% - 70px);
  }

  .overflow-section {
    min-width: 1300px;
  }
}
/* @media (max-width: 1440px) and (min-width: 1366px)  */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1440px)
{
  /* .ww2.col-md-2 {
    display: none;
  }

  .w-58 {
    width: 69%;
  } */

  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 150px;
  }

  .width-in {
    padding-left: 30px;
    padding-right: 30px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 190px;
  }

  .mx-3.dat {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .w-70 {
    width: 60px;
  }

  .row.db-grid .col-md-3 {
    width: 33.33%;
  }

  .h-85 {
    min-height: 64px !important;
  }

  .new-table-design.spacing-table td:nth-child(1),
  .new-table-design.spacing-table th:nth-child(1) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(2),
  .new-table-design.spacing-table th:nth-child(2) {
    width: 135px;
    min-width: 135px;
  }

  .new-table-design.spacing-table td:nth-child(3),
  .new-table-design.spacing-table th:nth-child(3) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(4),
  .new-table-design.spacing-table th:nth-child(4) {
    width: 80px;
    min-width: 80px;
  }

  .new-table-design.spacing-table td:nth-child(5),
  .new-table-design.spacing-table th:nth-child(5) {
    width: 80px;
    min-width: 80px;
  }

  .new-table-design.spacing-table td:nth-child(6),
  .new-table-design.spacing-table th:nth-child(6) {
    width: 73px;
    min-width: 73px;
  }

  .new-table-design.spacing-table td:nth-child(7),
  .new-table-design.spacing-table th:nth-child(7) {
    /* width: 110px; */
  }

  .ft-table-v.table td {
    font-size: 18px !important;
  }

}
/* @media (max-width: 1365px) and (min-width: 1200px)  */
@media only screen and (min-device-width: 1200px) and (max-device-width: 1365px)
{

  /* .ww2.col-md-2 { display: none;} */



  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 150px;
  }

  .width-in {
    padding-left: 32px;
    padding-right: 32px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  /* .exception-header .text-end.col-md-3{display: none;} */
  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 190px;
  }


  .width-in {
    overflow: auto;
  }

  .width-in::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .width-in::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
  }

  .width-in::-webkit-scrollbar-thumb {
    background: #a7c6e4;
    border-radius: 30px;
  }



  .row.db-grid .col-md-3 {
    width: 33.33%;
  }

  .modal-dialog {
    max-width: 1050px;
  }

  .h-85 {
    min-height: 64px !important;
  }

  .new-table-design.spacing-table td:nth-child(1),
  .new-table-design.spacing-table th:nth-child(1) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(2),
  .new-table-design.spacing-table th:nth-child(2) {
    width: 91px;
    min-width: 91px;
  }

  .new-table-design.spacing-table td:nth-child(3),
  .new-table-design.spacing-table th:nth-child(3) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(4),
  .new-table-design.spacing-table th:nth-child(4) {
    width: 127px;
    min-width: 127px;
  }

  .new-table-design.spacing-table td:nth-child(5),
  .new-table-design.spacing-table th:nth-child(5) {
    width: 110px;
    min-width: 110px;
  }

  .new-table-design.spacing-table td:nth-child(6),
  .new-table-design.spacing-table th:nth-child(6) {
    width: 73px;
    min-width: 73px;
  }

  .new-table-design.spacing-table td:nth-child(7),
  .new-table-design.spacing-table th:nth-child(7) {
    /* width: 110px; */
  }

  th.coll {
    font-size: 13px;
  }

  .ft-table-v.table td {
    font-size: 18px !important;
  }

  .rt-v .next {
    right: -29px;
  }

  .rt-v .prev {
    left: -29px;
  }
}
/* @media (max-width: 1199px) and (min-width: 950px)  */
@media only screen and (min-device-width: 950px) and (max-device-width: 1199px)
{
  /* .ww2.col-md-2 { display: none;} */
  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 150px;
  }

  .width-in {
    padding-left: 32px;
    padding-right: 32px;
  }

  .rt-v .prev {
    left: -28px;
  }

  .rt-v .next {
    right: -28px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  /* .exception-header .text-end.col-md-3{display: none;} */
  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 190px;
  }

  .width-in {
    overflow: auto;
  }

  .width-in::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .width-in::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
  }

  .width-in::-webkit-scrollbar-thumb {
    background: #a7c6e4;
    border-radius: 30px;
  }

  .row.db-grid .col-md-3 {
    width: 50%;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .activedailymanagement-s {
    min-width: 970px;
  }

  .department-details-page,
  .current-previous-period-page {
    min-width: 950px;
  }

  .h-85 {
    min-height: 64px !important;
  }

  .new-table-design.spacing-table td:nth-child(1),
  .new-table-design.spacing-table th:nth-child(1) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(2),
  .new-table-design.spacing-table th:nth-child(2) {
    width: 91px;
    min-width: 91px;
  }

  .new-table-design.spacing-table td:nth-child(3),
  .new-table-design.spacing-table th:nth-child(3) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(4),
  .new-table-design.spacing-table th:nth-child(4) {
    width: 127px;
    min-width: 127px;
  }

  .new-table-design.spacing-table td:nth-child(5),
  .new-table-design.spacing-table th:nth-child(5) {
    width: 110px;
    min-width: 110px;
  }

  .new-table-design.spacing-table td:nth-child(6),
  .new-table-design.spacing-table th:nth-child(6) {
    width: 73px;
    min-width: 73px;
  }

  .new-table-design.spacing-table td:nth-child(7),
  .new-table-design.spacing-table th:nth-child(7) {
    /* width: 110px; */

  }

  th.coll {
    font-size: 13px;
  }

  .header-bar .col .css-b62m3t-container .css-13cymwt-control,
  .header-bar .col .css-t3ipsp-control {
    outline: none !important;
    box-shadow: none !important;
    height: 55px !important;
    font-size: 16px !important;
  }
}
/* @media (max-width: 949px) and (min-width: 768px)  */
@media only screen and (min-device-width: 768px) and (max-device-width: 949px)
{

  /* .ww2.col-md-2 { display: none;} */
  .w-58 .cl2 {
    flex: 0 0 auto;
    width: 150px;
  }

  .width-in {
    padding-left: 32px;
    padding-right: 32px;
  }

  .rt-v .prev {
    left: -28px;
  }

  .rt-v .next {
    right: -28px;
  }

  .min-h7 {
    height: calc(71vh + 13px);
  }

  /* .exception-header .text-end.col-md-3{display: none;} */
  .w-58 .form-inline .col:nth-child(1) {
    flex: 0 0 auto;
    width: 190px;
  }

  .width-in {
    overflow: auto;
    margin-bottom: 15px;
  }

  .width-in::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .width-in::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
  }

  .width-in::-webkit-scrollbar-thumb {
    background: #a7c6e4;
    border-radius: 30px;
  }

  .row.db-grid .col-md-3 {
    width: 50%;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .activedailymanagement-s {
    min-width: 970px;
  }

  .department-details-page,
  .current-previous-period-page {
    min-width: 950px;
  }

  .h-85 {
    min-height: 64px !important;
  }

  .new-table-design.spacing-table td:nth-child(1),
  .new-table-design.spacing-table th:nth-child(1) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(2),
  .new-table-design.spacing-table th:nth-child(2) {
    width: 91px;
    min-width: 91px;
  }

  .new-table-design.spacing-table td:nth-child(3),
  .new-table-design.spacing-table th:nth-child(3) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(4),
  .new-table-design.spacing-table th:nth-child(4) {
    width: 127px;
    min-width: 127px;
  }

  .new-table-design.spacing-table td:nth-child(5),
  .new-table-design.spacing-table th:nth-child(5) {
    width: 110px;
    min-width: 110px;
  }

  .new-table-design.spacing-table td:nth-child(6),
  .new-table-design.spacing-table th:nth-child(6) {
    width: 73px;
    min-width: 73px;
  }

  th.coll {
    font-size: 13px;
  }

  .modal-dialog .width-in .header-bar .col {
    width: 50% !important;
    max-width: 50% !important;
    flex: auto;
    margin: 3px 0px;
  }

  .realtime-page .header-bar {
    min-width: 950px;
  }

  .modal-dialog .realtime-page .header-bar {
    min-width: auto;
  }

  .ww1 {
    min-width: 25%;
  }

  .home-widget .sp2 {
    width: 10% !important;
    justify-content: center;
  }

  h3,
  .h3 {
    font: normal normal bold 1.2rem/2rem 'F37 Moon Demi';
  }

  .css-udadfb-ButtonBase,
  .css-178ag6o {
    font: normal normal bold 1.2rem/2rem 'F37 Moon Demi';
  }
}
/* --------------------Added by sumit on 16may---------------------------------- */
/* @media (max-width:1152px) and (min-width: 768px)  */
/* @media only screen and (min-device-width: 768px) and (max-device-width: 1152px) */
@media (min-width: 768px) and (max-width: 1152px)
{
  .hIgppe, .kpaCiq {
    padding: 0px 10px !important;
  }

  .f-35 {
    font-size: 12px;
  }

  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 12px;
  }

  .shadow-box {
    padding: 7px 8px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    margin: 6px 2px !important;
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Sailec';
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    /* max-height: 480px; */
    min-height: 400px !important;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 11px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }

  .hd th {
    font-size: 11px !important;
  }

  .new-table-design.spacing-table td:nth-child(1),
  .new-table-design.spacing-table th:nth-child(1) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(2),
  .new-table-design.spacing-table th:nth-child(2) {
    width: 91px;
    min-width: 91px;
  }

  .new-table-design.spacing-table td:nth-child(3),
  .new-table-design.spacing-table th:nth-child(3) {
    width: 70px;
    min-width: 70px;
  }

  .new-table-design.spacing-table td:nth-child(4),
  .new-table-design.spacing-table th:nth-child(4) {
    width: 127px;
    min-width: 127px;
  }

  .new-table-design.spacing-table td:nth-child(5),
  .new-table-design.spacing-table th:nth-child(5) {
    width: 110px;
    min-width: 110px;
  }

  .new-table-design.spacing-table td:nth-child(6),
  .new-table-design.spacing-table th:nth-child(6) {
    width: 73px;
    min-width: 73px;
  }

  .new-table-design.spacing-table td:nth-child(7),
  .new-table-design.spacing-table th:nth-child(7) {
    /* width: 110px; */
  }

  th.coll {
    font-size: 13px;
  }
  h3, .h3 {
    font: normal normal bold 1.2rem / 2rem 'F37 Moon Demi';
}
.css-udadfb-ButtonBase, .css-178ag6o{
  font-size: 1.1rem;
}
}
@media (min-width: 1024px) {
  .row.db-grid .col-md-3 {
    /* width: 20%; */
  }
}

@media (max-width: 1024px) {
  .custom-view-2{display: block !important;}
  .custom-view-1{display: none !important;}
}
@media (max-width: 1440px) {
  .px .ag-chart-wrapper {
    max-height: 230px !important;
  }
  .border-btn {
    padding: 6px 5px;}

}
@media (max-width: 1600px) {
  /* .rt-v .next { right: -23px;}
.rt-v .next img{width: 25px;}
.rt-v .prev img{width: 25px;}
.rt-v .prev{  left: -23px; } */
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
  .mobile-content-center {
    justify-content: center !important;
  
}
.mobile-m-4{  margin: 15px 0px;}
  .col-md-7.ps-2 {
    width: 100%;
    padding-left: 0 !important;
    margin-top: 7px;
  }

  .col-4.h-100 {
    margin-top: 4px;
  }

  .av-btn {
    padding: 8px 6px;
  }

  .av-btn .f-14 {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .alert {
    margin-top: 2px;
  }

  h3,
  .h3 {
    font: normal normal bold 1.2rem/2rem 'F37 Moon Demi';
  }

  .home-widget .widget-tabs {
    padding: 20px 10px 0;
  }

  h2 {
    font: normal normal 700 2rem/2.2rem 'F37 Moon Demi';
  }

  .width-in {
    width: calc(100% - 0px);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    transition: width 0.3s;
    margin-left: 0 !important;
  }

  .rt-v button {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 10px;
  }

  .react-datepicker-wrapper input {
    width: 120px;
  }

  .sidebar--close {
    transition: width 0.3s
  }

  .sidebar--close.sidebar--open {
    width: 115px;
    position: fixed;
    z-index: 999;
    top: 0;
  }

  /* .sidebar--close.sidebar--open + .width-in { width: calc(100% - 130px); } */
  .header-bar .col {
    flex: auto !important;
    margin: 4px 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .header-bar .col .form-control {
    height: 45px;
  }

  .modal-dialog {
    margin: 10px 5% auto;
  }

  .header-bar .col:nth-child(6) {
    width: 100%;
    max-width: 100%;
  }

  .box-h {
    padding: 11px 5px;
  }

  /* .col3{width:30px; padding:0px;} */
  /* .col.col1 { flex: 0 0 auto;width: 35%;}
    .col.col1 img{max-width: 100%; height: auto !important;}
    .col.col2 { flex: 0 0 auto;width: 50%;}
    .col.col2 img{max-width: 100%; height: auto !important;} */
  /* .bulb{display: none;} */
  .sidebar ul li a img {
    width: 30px !important;
    height: 30px !important;
  }

  .ft-text .f-14 {
    font-size: 12px;
  }

  .text-mobile-center {
    text-align: center !important;
  }

  div#chart.yh {
    min-height: 300px;
  }

  .bg-white.p-3 .w-auto-col .d-flex.align-items-center {
    width: 100%;
    justify-content: center;
    padding: 5px 0px;
  }

  .min-width-730 {
    min-width: 700px;
  }

  .mobile-overflow-auto {
    overflow: auto;
  }

  .mobile-w-auto {
    width: auto !important;
  }

  .min-width-730 .sp2 {
    width: auto !important;
    min-width: 60px;
    margin-top: 5px !important;
    font-size: 15px;
  }

  .min-width-730 .sp1 {
    font-size: 14px;
  }

  .mobile-w-auto img {
    height: 27px;
    width: auto;
  }

  .mobile-my-2 {
    margin: 7px 0px;
  }

  .sidebar--close ul li a {
    padding: 5px 5px;
  }

  .sidebar--close {
    width: 0;
    transition: all 0.3s;
    padding: 0 !important;
    position: absolute;
    z-index: 9999;
  }

  .mobile-m-0 {
    margin: 0 !important;
  }

  .mobile-pe-0 {
    padding-right: 0 !important;
  }

  .dekstop-only {
    display: none;
  }

  .top-bar .logos img {
    height: 35px;
  }

  .container {
    width: 100%;
    max-width: 100%;
  }

  .sidenavarea.active {
    visibility: visible;
    --tw-bg-opacity: 0.5;
  }

  .sidenavarea {
    visibility: hidden;
    position: fixed;
    inset: 0px;
    z-index: 49;
    background-color: #484848a1;
    --tw-bg-opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
  }

  .sidebar {
    min-height: calc(100vh - 0px);
  }

  .widget-tabs .box-h {
    padding: 5px 0px;
  }

  .menuicon {
    height: 15px !important;
    margin-top: 10px;
    margin-right: 13px !important;
  }

  .f-16 {
    font-size: 13px;
  }

  .i img {
    height: 26px;
    margin-right: 7px;
  }

  .w-58 {
    width: 100%;
  }

  .w-58 .col {
    flex: 0 0 auto;
    width: 100%;
  }

  .w-58 .col-3 {
    flex: 0 0 auto;
    width: 100%;
  }

  .w-58 .col-3.mx-3 {
    margin-left: 0 !important;
  }

  .hIgppe {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 15px;
  }

  .activedailymanagement-s .text-end.mobile-center {
    text-align: center !important;
  }

  .scroll-table {
    position: relative;
    min-width: 700px;
  }

  .height-80v#chart {
    height: 100% !important;
  }

  div#chart {
    height: 100% !important;
    overflow: auto;
  }

  div#chart>div {
    min-width: 700px;
    min-height: 290px !important;
  }

  .h-half {
    height: auto;
  }

  .h-454 {
    /* min-height: calc(65vh + 16px); */
    min-height: auto;
  }

  .modal-dialog.modal-dialog-centered.modal-lg {
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 0 !important;
    margin: 0 auto;
    min-height: 100vh !important;
  }

  h5 {
    font: normal normal bold 1rem/1rem 'F37 Moon Demi';
  }

  span.circle-i {
    font-size: 11px;
    line-height: 28px;
    height: 28px;
    width: 28px;
  }

  .modelcurrprev .modal-content .width-1 {
    width: 100%;
  }

  .modelcurrprev .modal-content .width-2 {
    width: 100%;
    margin-top: 7px;
  }

  .modal .width-in {
    padding: 7px 5px !important;
    margin-left: 0px !important;
  }

  .modelcurrprev .modal-content .width-2 .spacing-table {
    min-width: 600px;
  }

  .h-607 {
    min-height: auto;
  }

  .modelcurrprev .closeabsolute {
    right: 0px;
    top: 28px;
  }

  .f-25 {
    font-size: 16px;
  }

  .border-left-1 {
    border-left: 0px !important
  }

  .border-end {
    border-right: 0px !important;
  }

  .border-start {
    border-left: 0px !important;
  }

  .department-details-page .spacing-table {
    min-width: 600px;
  }

  .top-bar .timeline a {
    font-size: 0 !important;
    margin: 0;
    margin-right: 5px;
    padding: 0px 4px !important;
    height: 31px !important;
    line-height: 31px !important;
  }
  .top-bar .timeline a img{    margin-right: 0px !important;}
  .mobile-order-2{order: 2;}
  .custom-select-v.d-inline-block button + div {
    text-align: left;
}

  .bulb {
    margin-right: 5px;
  }

  .top-bar .profile-cover {
    width: 34px !important;
    height: 34px !important;
  }

  .modal-dialog .scroll-table tbody {
    max-height: calc(100vh - 450px);
  }

  .scroll-table tbody.h-472 {
    display: block;
    /* max-height: 555px; */
    max-height: calc(100vh - 450px);
    overflow: auto;
    /* min-height: 150px; */
  }

  .missed-meal-break .modal-content .custom-font-size h4 {
    flex-wrap: wrap;
    flex-direction: column;
  }

  h4 {
    font: normal normal bold 1.2rem/2rem 'F37 Moon Demi';
  }

  .resp .mobile-wrap {
    flex-wrap: wrap;
  }

  .mobile-margin-0 {
    margin: 0 !important;
  }

  .mobile-w-100 {
    width: 100%;
    text-align: center;
  }

  .mobile-mb-10 {
    margin-bottom: 10px !important;
  }

  .mobile-w-600 {
    overflow: auto;
  }

  .mobile-w-600>div {
    min-width: 700px;
  }

  .custom-select {
    font: normal normal bold 1.1rem/1.7rem 'F37 Moon Demi' !important;
  }

  .next {
    right: -20px;
    z-index: 9;
  }

  .prev {
    left: -20px;
    z-index: 1;
  }

  .px-5.modal-body {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .px-5.modal-body .header-bar .col {
    flex: auto;
    margin: 4px 0;
    width: 100%;
    max-width: 100%;
  }

  .header-bar .col .css-b62m3t-container .css-13cymwt-control,
  .header-bar .col .css-t3ipsp-control {
    outline: none !important;
    box-shadow: none !important;
    height: 49px !important;
    font-size: 14px !important;
  }

  .mobile-flex-wrap {
    flex-wrap: wrap !important;
  }

  .css-udadfb-ButtonBase,
  .css-178ag6o {
    font: normal normal bold 1.2rem/2rem 'F37 Moon Demi';
  }

  .bxh .legend-text+.legend-text {
    margin-left: 4px;
  }

  .filter-btn .ft-btn {
    font-size: 12px;
    padding: 3px 3px;
  }

  .scroll-table.long-table.big-table {
    min-width: 1200px;
  }

  .mobile-mt-0 {
    margin-top: 0px !important;
  }

  .mobile-half.col {
    width: 50%;
    min-width: 50%;
    margin-bottom: 0.5rem;
  }

  .scroll-table tbody:not(.ftr) {
    min-height: 300px;
  }
  .first-t td:nth-child(1), .first-t th:nth-child(1) {
    width: 170px;
  }
  





}
@media (min-width: 768px) {
  .toggle-menu-btn { display: none; }
  .mobile-only {   display: none;}
  .header-bar.gx-1 {   padding-right: 5px;} 
}
@media only screen and (min-device-width: 480px) and (max-device-width: 990px) and (orientation: landscape) {

  .h-650 .scroll-table tbody { max-height: calc(100vh - 90px);}
  .scroll-table.long-table tbody { max-height: calc(100vh - 90px);}
}

.purplexyt {
  font-size: 14px;
  font-weight: 400;
}
.purplexyt .circle {
  background-color: #1c87cf
}

.bluexyt {
  font-size: 14px;
  font-weight: 400;
}

.bluexyt .circle {
  background-color: #458FFF
}
.whitexyt {
  font-size: 14px;
  font-weight: 400;
}
.whitexyt .circle {
  background-color: #ffffff
}
.blackxyt {
  font-size: 14px;
  font-weight: 400;
}
.blackxyt .circle {
  background-color: #000000
}
.header-bar .col .css-16xfy0z-control {
  background-color: unset !important
}
.width100-5{width: calc(100% - 5px) !important;}
.custom-select-v button + div {
  text-align: left;
}



/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7f7f7f;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1.5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.h-150{max-height: 150px !important;}
.modal-content .h-150{max-height: 350px !important;}
span.shwmore {
  color: #03A9F4;
  /* font-weight: 500; */
  cursor: pointer;
  /* font-size: 14px; */
  margin-left: 5px;
}
.spacing-table.comment-table td {
  padding: 10px 4px;
}
.spacing-table.comment-table th {
  padding: 5px 4px;
}
.approve.active{box-shadow: 0 0 0 0.25rem rgb(6 255 53 / 25%);
  border-radius: 50%;}
  .reject.active{box-shadow: 0 0 0 0.25rem rgb(255 49 49 / 25%);
    border-radius: 50%;}
    .img-cursor-pointer img{cursor: pointer;width: 25px;}
    .w-100-input input {
      width: 100%;
      height: 45px;
      border-radius: 5px;
      font-size: 12px;
      padding: 7px;outline: none !important; background: transparent;
      border: 1px solid #828282
  }
 .add-input-field {
    border: 1px solid #ced4da !important;
}
  .w-100-input input:focus {
 border-color: #86b7fe !important;
  }
  .w-100-input input.redborder{border: 1px solid #FF4560 !important;}
  .tdx {
    padding-left: 20px !important;
  }
  .width-180 {
    width: 220px;
  }
  .realtime-nodata {
    padding: 1.4rem 0 1.2rem;
  }
  .ag-chart-no-data-overlay {
    display: none !important;
  }
  .w-40r {
    min-width: 120px;
    text-align: left;
  }
  span.realTimenu {
    min-width: 42px;
    display: inline-block;
    text-align: center;
  }
    .spinner-container {
      height: 250px;
      /* Set desired height */
      display: flex;
      justify-content: center;
      align-items: center;
    }

        .maintenance-banner {
          display: flex;
          /* Dark red color */
          color: white;
          height: 50px;
          align-items: center;
        }
    
        .banner-left {
          background-color: #9e2d24;
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 15px;
          white-space: nowrap;
          height: 50px
        }  

    
        .banner-left img {
          width: 21px;
          margin-right: 0.5rem;
        }
    
        .banner-right {
          background-color: #e9ebed;
          color: #333;
          flex-grow: 1;
          padding: 0 10px;
          display: flex;
          align-items: center;
          font-size: 13px;
          height: 50px
        }
    
        .banner-right p {
          margin: 0;
        }
    
        .ht-banner {
          height: calc(-50px + 100vh);
        }
        .db-grid .ag-chart-wrapper { height: 200px !important;}
        .db-grid .shadow-box {  height: 100%;}  
        .realtime-nodata {
           padding: 0rem 0 0rem; 
      }
      @media (max-width: 1800px) and (min-width:1650px)
      {
        .db-grid .ag-chart-wrapper {
           height: 130px !important;
          }
      }
      @media (max-width: 1649px) and (min-width:1400px)
      {
        .db-grid .ag-chart-wrapper { 
          height: 170px !important;}
        .row.db-grid .col-md-3.UU {  width: 33.33%;
        }
      }
      @media (max-width: 1399px) and (min-width:1200px)
      {
        .db-grid .ag-chart-wrapper {  height: 160px !important;}
        .row.db-grid .col-md-3.UU {     width: 33.33%;}
      }