
    .nav-pills .v-tab.active, .nav-pills .show>.v-tab {
        color: #1b711e;
        background-color: #e2f3eb;
    }
    .w-220{width: 220px;}
    .w-220 .nav-item+.nav-item{margin-top: 20px;}
    .min-height-profile{min-height: calc(85vh + 10px); margin-bottom: 10px;}
    .border-left-1{border-left: 1px solid #DDD;    min-height: calc(70vh + 10px);}
    .size-100{ line-height: 120px; font-size: 24px;  display: inline-block;  }
    .size-100 .profile-pic{border-radius: 50%;width: 120px;height: 120px;border: 5px solid #5E8E28;}
    .profile-form .form-control{height: 50px;}
    .profile-form .form-label {
        font-weight: 500;
    }
    .camera{position: absolute;
        bottom: 0;
        right: 2px;
        background: #5E8E28;
        padding: 5px;
        border-radius: 50%;}
    .incr-eye{position: absolute;
        bottom: 20px;
        right: 30px;}
        button.btm {
            border: 0px;
        }
        .profile-form .form-control[disabled] {
            cursor: not-allowed !important;pointer-events: all;
        }
        .cus-btn{height: 45px;
            margin-bottom: 28px;
            border-radius: 0.1875rem !important;
            display: block;}