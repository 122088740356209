.modal-dialog.modal-md {
    max-width: 70%;
}
@media (max-width: 768px){
    .modal-dialog.modal-md {
        max-width: 90%;
    }
    .custom-font-size .ft-title.f-18.modal-title.h4 {
        font-size: 19px !important;
    }
    .ft-table-v.table th {
        font-size: 15px !important;
    }
    .big-font.f-18 {
        font-size: 18px !important;
        line-height: 1;
    }
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}
.max-w-620 .modal-dialog{max-width: 620px !important;}
.max-w-720 .modal-dialog{max-width: 720px !important;}
.max-w-1020 .modal-dialog {
    max-width: 1050px !important;
}
.custom-position .btn-close {
    position: absolute;
    right: 20px;
    top: 50px;
}