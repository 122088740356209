.reportactive{background:#e7ffcc}
.minheight60{min-height: 60px;}
.pos-s{left: 15px;
    top: 9px;}
    @media (max-width: 1440px) and (min-width: 1300px)
    {
.pos-s {
    font-size: 10px !important;
}
    }

.pos-w {
    left: 15px;
}
@media (max-width: 1440px) and (min-width: 1300px) {
    .pos-w {
        font-size: 10px !important;
    }
}