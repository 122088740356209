
.ft-table-v.table th {font-size: 22px;}
.big-font.f-18 {
    font-size: 24px !important;
    line-height: 1;
}
.ft-table-v.table th, .ft-table-v.table td{vertical-align: middle;}
small.f-12 {
    font-size: 11px !important;
}
@media (max-width: 1600px) and (min-width: 1441px){
.ft-table-v.table td{
    font-size: 22px !important;
}
.ft-table-v.table td.big-font.f-18 {
    font-size: 24px !important;
}
}
.table.ft-table-v >:not(caption)>*>* {
    padding: 1rem 0.5rem;
}
.custom-font-size .ft-title.f-18.modal-title.h4 {
    font-size: 28px;
}