.newrealtime .header-bar {
    display: none;
}

.realtime-table tbody,
.realtime-table thead {
    display: table-row-group !important;
}

.spacing-table.realtime-table {
    border-spacing: 0px 12px;
    width: calc(93% - 7px);
    margin: 0 auto;
}

.spacing-table.realtime-table td {
    padding: 25px 10px;
}

.circle-30 {
    height: 25px;
    width: 25px;
    display: block;
    border: 1px solid;
    border-radius: 50%;
    margin: 0 auto;
}

.red-productive {
    background-color: #ff2626;
}

.green-productive {
    background-color: #0a9d2c;
}

.yellow-productive {
  background-color: #ffff26;
}

.h-540 {
    height: calc(100vh - 300px)
}
.side-gap{width: 305px; }
.realtime-table tr td {
    font-size: 1.5rem;
    font-weight: 500;
}

.realtime-table tr th {
    font-size: 1.6rem;
    line-height: 1.2;
}

.f-25rem {
    font-size: 2.5rem;
}

.spacing-table.realtime-table td {
    border-width: 3px 0
}

.spacing-table.realtime-table td:first-child {
    border-left-width: 3px;
    border-radius: 25px 0 0 25px;
}

.spacing-table.realtime-table td:last-child {
    border-right-width: 3px;
    border-radius: 0px 25px 25px 0px;
}


.spacing-table.realtime-table thead,
.spacing-table.realtime-table tbody tr {
    display: table !important;
    width: 100%;
    /* table-layout: fixed; */
}

.spacing-table.realtime-table tbody {
    display: block !important;
    overflow: auto;
    padding-right: 4px;
}

.spacing-table.realtime-table tbody {
    margin-top: -12px;
}
.spacing-table.realtime-table thead tr th:nth-child(1),
.spacing-table.realtime-table tbody tr td:nth-child(1)
 {
/* width: 200px; min-width: 250px; */
min-width: 250px;
 }
.spacing-table.realtime-table thead tr th:nth-child(2),
.spacing-table.realtime-table tbody tr td:nth-child(2)
 {
width: 130px;min-width: 130px;
 }
.spacing-table.realtime-table thead tr th:nth-child(3),
.spacing-table.realtime-table tbody tr td:nth-child(3)
 {
width: 140px;min-width: 140px;
 }
.spacing-table.realtime-table thead tr th:nth-child(4),
.spacing-table.realtime-table tbody tr td:nth-child(4)
 {
width: 175px;min-width: 175px;
 }
.spacing-table.realtime-table thead tr th:nth-child(5),
.spacing-table.realtime-table tbody tr td:nth-child(5)
 {
width: 160px;min-width: 160px;
 }
.spacing-table.realtime-table thead tr th:nth-child(6),
.spacing-table.realtime-table tbody tr td:nth-child(6)
 {
width: 175px;min-width: 175px;
 }
.spacing-table.realtime-table thead tr th:nth-child(7),
.spacing-table.realtime-table tbody tr td:nth-child(7)
 {
width: 180px;min-width: 180px;
 }
.spacing-table.realtime-table thead tr th:nth-child(8),
.spacing-table.realtime-table tbody tr td:nth-child(8)
 {
width: 192px;min-width: 192px;
 }
.text-break tr th {white-space:normal;}
  
  
  /*  Toggle Switch  */
  .button {
    position: relative;
    top: 50%;
    width: 55px;
    height: 34px;
    margin: 0px auto 0 auto;
    overflow: hidden;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }
  
  .button.b2 {
    border-radius: 2px;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  .button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  /* Button 1 */
  #button-1 .knobs:before {
    content: "No";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 28px;
    height: 28px;
    color: #fff;
    font-size: 11px;
    /* font-weight: bold; */
    text-align: center;
    line-height: 28px;
    /* padding: 9px 4px; */
    background-color: #f44336;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }
  
  #button-1 .checkbox:checked + .knobs:before {
    content: "Yes";
    left: 24px;
    background-color: #02b100;
  }
  
  #button-1 .checkbox:checked ~ .layer {
    background-color: #fcebeb;
  }
  
  #button-1 .knobs,
  #button-1 .knobs:before,
  #button-1 .layer {
    transition: 0.3s ease all;
  }
  .cost-center-name{min-height: 68px;    display: flex;
    align-items: center;
    justify-content: center; flex-wrap: wrap;}