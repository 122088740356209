.missed-meal-break .modal-header {
    padding: 0;
    border: 0;
}
.missed-meal-break .modal-header .btn-close {
    margin-left: 2em;
    position: absolute;
    right: 15px;
    top: 43px;z-index: 9;
}
.missed-meal-break  .top-bar{display: none;}
.missed-meal-break .modal-content .custom-font-size h4 {
justify-content: center;
  }