.layout {
    position: relative;
}

.calc-h100 {
    height: calc(100% - 140px);
}

.header {
    width: 100%;
    height: 10vh;
    background: #E0E0E0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.content {
    overflow-y: scroll;
    padding-bottom: 20px;
    margin: 0 auto;
    width: 75%;
    height: 85vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 46px #00000029;
    opacity: 1;
}

.searchform {
    position: relative;
}

.searchwrap {
    position: absolute;
    background: #fff;
    z-index: 999;
    width: 100%;
    box-shadow: 0 9px 23px 0px rgb(0 0 0 / 16%);
}

.searchwrap ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.searchwrap ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
}

.searchwrap ul li span {
    color: #888;
    font-size: 13px;
}

.searchwrap ul li p {
    margin-bottom: 0;
    color: #888;
    font-size: 12px;
}


.h-20 {
    height: 20px;
}

img.h-20 {
    vertical-align: baseline;
}

.drop button {
    border: 0px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    line-height: 32px;
    font-family: Sailec;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}

.dropdown-item img {
    margin-right: 15px;
}

.sidebar--close {
    width: 135px;
}

.sidebar--close ul li span {
    display: block;
}

.sidebar--close ul li a {
    text-align: center;
    padding: 5px 10px;
}

.sidebar--close ul li a span {
    margin-top: 5px;
}

/* .sidebar--open {
    width: 300px;
} */

/* .sidebar--open ul li a {
    padding: 5px 15px;
}

.sidebar--open ul li a span {
    margin-left: 10px;
} */

.width-dec {
    width: calc(100% - 345px);
}

.width-in {
    width: calc(100% - 150px);
    padding-left: 50px;
    padding-right: 50px;
}

.apexcharts-canvas {
    background-color: '#fff' !important;
}

/* .width-in{overflow: hidden;} */
.bulb {
    cursor: pointer;
    margin-right: 20px;
    /* position: absolute;
    right: 60px;
    top: 18%;  */
}

/* The side navigation menu */
#mySidenav {
    height: calc(100% - 65px);
    /* 100% Full-height */
    width: 0px;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 60px;
    /* Stay at the top */
    right: 0;
    background-color: #ffffff;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */

    padding-top: 10px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    box-shadow: 0 7px 8px 0 hsla(0, 0%, 87%, .5);
}

#mySidenav.sidenotificationbar {
    width: 350px;
}

/* The navigation menu links */
#mySidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */


/* Position and style the close button (top right corner) */
#mySidenav .closebtn {
    position: absolute;
    top: -5px;
    right: 5px;
    font-size: 36px;
    margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    #mySidenav {
        padding-top: 15px;
    }

    #mySidenav a {
        font-size: 18px;
    }
}

.notify-tabs {
    padding: 20px 30px;
    margin: 15px 0px;
    background: #f1f1f1;
    box-shadow: 0px 5px 5px #e9e9e9de;
    min-width: 330px;
}

.pdf {
    cursor: pointer;
}

.notification-sec {
    padding: 10px;
    opacity: 0;
}

#mySidenav h5 {
    opacity: 0;
    white-space: nowrap;
}

#mySidenav .closebtn {
    opacity: 0;
}

#mySidenav.sidenotificationbar .notification-sec {
    opacity: 1;
}

#mySidenav.sidenotificationbar h5 {
    opacity: 1;
}

#mySidenav.sidenotificationbar .closebtn {
    opacity: 1;
}

/* .bulb.active { filter: brightness(5);} */
/* .bulb.active {
    filter: drop-shadow(2px 4px 6px);
} */
.bulb {
    background: transparent;
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.bulb.active {
    background: #353535;
}

.user-d {
    padding: 0;
}

.user-d.dropdown-toggle::after {
    display: none;
}

.header-bar .col .form-control {
    height: 49px;   font-size: 15px !important;
}

.css-1nmdiq5-menu {
    min-width: 200px;
}

.css-1nmdiq5-menu::-webkit-scrollbar {
    width: 5px !important;
}

.css-1nmdiq5-menu::-webkit-scrollbar-track {
    background: #DEEBFD !important;
    border-radius: 30px !important;
}

.css-1nmdiq5-menu::-webkit-scrollbar-thumb {
    background: #a7c6e4 !important;
    border-radius: 30px !important;
}

.css-udadfb-ButtonBase,
.css-178ag6o {
    height: 2.2857142857142856em;
    line-height: 1.7em;
    font-size: 1.3rem;
}
.top-bar .css-udadfb-ButtonBase,
.top-bar .css-178ag6o {
    height: 2.2857142857142856em;
    line-height: 2.28em;
    font-size: 1rem;
}



.Bi_Su .css-udadfb-ButtonBase,
.Bi_Su  .css-178ag6o {
    line-height: 2em;
}
.border-dropdown{border: 1px solid #828282!important;
    border-radius: 5px;
    padding: 5px;
    height: 56px;
}
.border-dropdown .custom-select-v button + div {
        right: 0;    margin-top: 10px !important;width: 100%;text-align: left;
}
.border-dropdown  .custom-select-v {
        position: absolute;
        right: 5px;
        left: 0;
}
.border-dropdown  .custom-select-v > div{text-align: right;}
.css-qbdosj-Input input{font-size:16px !important}
    
#mySidenav.sidemessagebar {
    width: 50%;max-width: 680px;
}
#mySidenav.sidemessagebar .closebtn {
    opacity: 1;
}
#mySidenav.sidemessagebar .notification-sec {
    opacity: 1;
}

#mySidenav.sidemessagebar h5 {
    opacity: 1;
}

#mySidenav.sidemessagebar .closebtn {
    opacity: 1;
}
.chat-date{max-width: 200px; margin: 0 auto;}
.chat-date .css-13cymwt-control, .chat-date .css-t3ipsp-control{height: auto !important;}
.chat-date .css-1fdsijx-ValueContainer {
    font-size: 14px;
}
.chating-area{display: flex; padding: 15px;}
.chat-perrson-img { width: 45px; margin-right: 20px;}
.chat-perrson-img img{width:45px; height: 45px; border-radius: 50%; object-fit: cover;} 
.chat-person {
    font-size: 14px;
    font-weight: 300;
}
.chat-dt {
    font-size: 13px;
    color: #8a8a8a;    font-weight: 300;
}
.chat-message {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}
.chat-area {
    max-height: calc(100vh - 310px);
    overflow: auto;
}
.chat-g{ position: relative; padding: 15px;   }
.chat-g input{    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    align-items: stretch;
    /* min-height: 48px; */
    justify-content: center;
    background-color: transparent;
    border-radius: 4px;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #424242;
    color: #FFF;
    font-weight: 300;
    font-size: 14px;padding-right: 50px;
    line-height: 1;}
    .chat-g button{
        position: absolute;
        right: 17px;
        top: 17px;
        line-height: 30px;
    }
    .chat-g button span{height: auto !important;line-height: 30px;font-size: inherit; }
    .custom-select-v.right-0 button + div {
        border: 1px solid #000 !important;
        background: #191C24 !important;
        margin: 0 !important;
        
        left: -200px !important;
    }




