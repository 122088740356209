/*
Name: 			main
Author: 		Sumit Sharma
Version: 		1.0.0
*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

:root {
  --cf-black-bg: #000;
  --cf-grey-bg: #f2f3f3;
  --cf-white-bg: #FFF;
  --cf-light-bg: #f9f9f9;
  --cf-orange-bg: #FFA800;
  --cf-green-bg: #28A745;
  --cf-blue-bg: #458FFF;


  --cf-color-white: #FFF;
  --cf-color-black: #000;
  --cf-color-orange: #F5720E;
  --cf-color-grey: #868686;
  --cf-orange-color: #FFA800;
  --cf-color-blue: #458FFF;
  --cf-color-green: #28A745;
  --cf-color-purple: #9E00FF;
  --cf-color-red: #FF4560;


  --cf-fp: 'Kanit', sans-serif;
  --cf-fs: 'Poppins', sans-serif;

  --cf-gap-150: 150px;
  --cf-gap-110: 110px;
  --cf-gap-100: 100px;
  --cf-gap-90: 90px;
  --cf-gap-80: 80px;
  --cf-gap-70: 70px;
  --cf-gap-60: 60px;
  --cf-gap-50: 50px;
  --cf-gap-40: 40px;
  --cf-gap-30: 30px;
  --cf-gap-20: 20px;

}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* body{font-family: var(--cf-fs); color: #404041;}
   .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{font-weight: 600;} */

.primary-font {
  font-family: var(--cf-fp)
}

.secondary-font {
  font-family: var(--cf-fs)
}

@media (min-width: 1400px) {
  .container {
    max-width: 90%;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 1712px;
  }
}

@media (max-width: 1400px) {
  .container {
    max-width: 97%;
  }
}


h1 {
  font: normal normal 700 2.8rem/3.5rem 'Kanit';
}

h2 {
  font: normal normal 700 2.5rem/3.2rem 'Kanit';
}

h4 {
  font: normal normal bold 1.6rem/2rem 'Kanit';
}

h3 {
  font: normal normal bold 1.8rem/2rem 'Kanit';
}

h5 {
  font: normal normal bold 1.2rem/2rem 'Kanit';
}

h6 {
  font: normal normal bold 1.1rem/2.3rem 'Kanit';
}

p {
  font: normal normal normal 1rem/1.4rem 'Poppins';
}

.big-para {
  font: normal normal normal 1.4rem/2rem 'Kanit';
}

.big-para-list {
  font: normal normal normal 1.2rem/1.8rem 'Kanit';
}


.dark--bg {
  background-color: var(--cf-black-bg);
}

.white--bg {
  background-color: var(--cf-white-bg);
}

.dark--grey {
  background-color: var(--cf-grey-bg);
}

.light--grey {
  background-color: var(--cf-light-bg);
}

.orange-bg {
  background-color: var(--cf-orange-bg);
}

.white-bg {
  background-color: var(--cf-white-bg);
}

.ptb--110 {
  padding-top: var(--cf-gap-110);
  padding-bottom: var(--cf-gap-110);
}

.ptb--100 {
  padding-top: var(--cf-gap-100);
  padding-bottom: var(--cf-gap-100);
}

.ptb--90 {
  padding-top: var(--cf-gap-90);
  padding-bottom: var(--cf-gap-90);
}

.ptb--80 {
  padding-top: var(--cf-gap-80);
  padding-bottom: var(--cf-gap-80);
}

.ptb--70 {
  padding-top: var(--cf-gap-70);
  padding-bottom: var(--cf-gap-70);
}

.ptb--60 {
  padding-top: var(--cf-gap-60);
  padding-bottom: var(--cf-gap-60);
}

.ptb--50 {
  padding-top: var(--cf-gap-50);
  padding-bottom: var(--cf-gap-50);
}

.ptb--40 {
  padding-top: var(--cf-gap-40);
  padding-bottom: var(--cf-gap-40);
}

.ptb--30 {
  padding-top: var(--cf-gap-30);
  padding-bottom: var(--cf-gap-30);
}

.ptb--20 {
  padding-top: var(--cf-gap-20);
  padding-bottom: var(--cf-gap-20);
}

.mt--150 {
  margin-top: var(--cf-gap-150);
}

.my--minus-70 {
  margin-top: -70px;
  margin-bottom: -70px;
}

.mt--70 {
  margin-top: var(--cf-gap-70);
}

.mt--40 {
  margin-top: var(--cf-gap-40);
}


.color-white {
  color: var(--cf-color-white)
}

.color-black {
  color: var(--cf-color-black)
}

.color-orange {
  color: var(--cf-orange-color);
}

.color-blue {
  color: var(--cf-color-blue);
}

.color-green {
  color: var(--cf-color-green);
}

.color-purple {
  color: var(--cf-color-purple);
}


.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-400 {
  font-weight: 400 !important;
}


.f-40 {
  font-size: 26px;
}

.f-35 {
  font-size: 22px;
}

.f-30 {
  font-size: 20px;
}

.f-25 {
  font-size: 18px;
}

.f-20 {
  font-size: 16px;
}

.f-18 {
  font-size: 15px;
}

.f-16 {
  font-size: 14px;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}


/* .shadow-box { box-shadow: -8px 12px 18px 0px #E9EFFE;  border-radius: 10px; padding: 10px 15px;border: 1px solid #DEEBFD;} */
.shadow-box {
  border-radius: 5px;
  padding: 10px 15px;
}

/* ----- change Shadow Box css by sumit on 16may ---------------------- */
.pipe {
  color: #A3A3A3;
  font-weight: 100;
}

.fields .form-control {
  height: 35px;
}

.mt-minus-25 {
  margin-top: -20px;
}

.search-box {
  background-color: #F4F4F4 !important;
}

.search-box::-webkit-input-placeholder {
  color: #C0C0C0 !important;
  font-size: 13px !important;
}

.search-box:-ms-input-placeholder {
  color: #C0C0C0 !important;
  font-size: 13px !important;
}

.search-box::placeholder {
  color: #C0C0C0 !important;
  font-size: 13px !important;
}

.nav-pills li button {
  width: auto !important;
  text-shadow: none !important;
  font-size: 12px !important;
  text-transform: capitalize !important;
  padding: 8px 10px !important;
  border: 1px solid #E1E1E1 !important;
  background: transparent !important;
  border-radius: 8px !important;
}

.nav-pills li button.active {
  background: #0E8BFF !important;
  color: #FFF !important;
}

.nav-pills li button.active img {
  filter: invert(1);
  width: auto;
}

.round-border {
  border: 1px solid #D9D9D9;
  border-radius: 18px;
  padding: 10px 15px;
}

.round-border p {
  margin: 0px;
}

.alert {
  padding: 10px 15px;
  border-radius: 18px;
  margin: 0;
}

.progress {
  width: 80%;
  height: 6px;
  border-radius: 5px;
}

.border-right::after {
  content: "";
  position: absolute;
  background-color: #A3A3A3;
  right: 0;
  width: 1px;
  height: 70%;
}

.ml-e>div+div {
  margin-left: 10px;
}

.border-right::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 40px;
  background: #A3A3A3;
  top: 25%;
}

.shadow-box .ui-shadow-inset {
  box-shadow: none !important;
  border: 0 !important;
  background: transparent !important;
}

.ui-content {
  padding: 0 !important;
}

.timeline .nav-pills li button.active {
  background: #28A745 !important;
}


/* =====================Date============================== */
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.fields .form-control {
  height: 35px;
  padding: 7px;
}

.chrt {
  margin-top: -15px;
}

.h-250 {
  height: 220px;
}

.form-select {
  padding: 5.5px 12px;
  font-size: 14px;
}

/* =================Range Slider============================ */
html>body .ui-rangeslider .ui-rangeslider-sliders .ui-slider-track:first-child,
.ui-slider-track {
  height: 8px !important;
}

.ui-slider-track {
  box-shadow: none !important;
  background-color: #E4E4E4 !important;
  border-color: #E4E4E4 !important;
}

.ui-slider-track .ui-btn.ui-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #28A745;
  border: 0px;
  margin: -12px 0 0 -15px !important;
}

.ui-slider-bg.ui-btn-active {
  background: linear-gradient(90deg, #28A745 15.54%, #A8F74D 100%);
}

.ui-page-theme-a .ui-slider-handle:hover {
  background: #28A745 !important;
}

.ui-slider-handle:nth-child(3) {
  background: #A8F74D !important;
}

.ui-rangeslider .ui-slider-track:first-child .ui-slider-bg {
  display: block !important;
}

/* =============================================== */

.i img {
  height: 25px;
  width: auto;
}

.plr-4 {
  padding-left: 4px;
  padding-right: 4px;
  ;
}

.ui-input-text,
.ui-input-search {
  margin: 0 0 !important;
}

.ui-mobile .ui-page-active {
  overflow: hidden !important;
}

.db-grid {
  max-height: 605px;
  overflow: auto;
}

.db-grid::-webkit-scrollbar {
  width: 5px;
}

.db-grid::-webkit-scrollbar-track {
  background: #DEEBFD;
  border-radius: 30px;
}

.db-grid::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}

.max-scoll {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.max-scoll::-webkit-scrollbar {
  width: 5px;
}

.max-scoll::-webkit-scrollbar-track {
  background: #DEEBFD;
  border-radius: 30px;
}

.max-scoll::-webkit-scrollbar-thumb {
  background: #a7c6e4;
  border-radius: 30px;
}

/* ============================================ */
.av-btn {
  text-shadow: none;
  cursor: pointer;
  color: #FFF !important;
  font-weight: 400 !important;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  min-width: 80px;
  text-align: center;
  text-decoration: none;
}

.green-btn {
  background: #5E8E28;
}

.blue-btn {
  background: #458FFF
}

.purple-btn {
  background: #9E00FF
}

.small-input .ui-input-text {
  width: auto;
  display: inline-block;
}

.small-input .ui-input-text .form-control,
.small-input .form-control {
  height: 25px !important;
  display: inline-block;
  width: 40px;
  border: 1px solid #6D6D6D;
  min-height: auto;
  text-align: center;
  line-height: 25px !important;
  font-size: 14px;
}

.h-line {
  height: 160px;
  background: #d1d1d1;
  width: 1px;
  margin-top: 20px;
  margin: 0 auto;
  margin-top: 20px;
}

.form-control[disabled] {
  pointer-events: none;
}

.form-control[disabled] {
  background-color: #F2F2F2;
  border: 0px !important;
}

#update {
  display: none;
}

.form-check-input[type=checkbox] {
  display: none;
}

label.form-check-label.ui-btn {
  padding: 4px 10px;
  padding-left: 2.5em;
}

button.ui-btn {
  width: auto;
}

button.ui-btn {
  width: auto !important;
  border: 0;
  box-shadow: none;
}

.submit-btn {
  background: #458FFF !important;
  text-shadow: none !important;
  color: #FFF !important;
  text-transform: capitalize;
  font-weight: 500 !important;
  letter-spacing: 1px;
}

textarea {
  text-shadow: none !important;
  box-shadow: none !important;
}

.apexcharts-legend-series {
  display: block !important;
  width: 100%;
}

.color-blue {
  color: #000 !important;
}

.btn-red {
  background: #FF4560 !important;
  text-shadow: none !important;
  color: #FFF !important;
}

.btn-success {
  background: #5E8E28 !important;
  text-shadow: none !important;
  color: #FFF !important;
}

.btn-blue {
  background: #008FFB !important;
  text-shadow: none !important;
  color: #FFF !important;
}

.modal-body .form-control {
  text-align: center;
  width: 65px;
  height: 25px;
  margin: 0 auto;
  color: #000;
  font-size: 14px;
  font-family: Sailec;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 24px;
  letter-spacing: 0.52px;
  padding: 0.375rem 0.25rem;
}

.para-5 p {
  margin-bottom: 5px;
}

.alert-success {
  background-color: #E6FFE8;
  color: #02B817;
}

.modal-dialog {
  max-width: 90%;
}

.right-20 {
  right: 20px;
}

.hd th {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  padding: 0px 5px;
  line-height: 1;
  vertical-align: bottom;
  text-align: center;
}

.t {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  text-align: right !important;
  padding-right: 10px !important;
}

.tu {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  text-align: right !important;
}

.modal {
  z-index: 9999 !important;
  background: rgb(0 0 0 / 65%);
}

textarea.full {
  width: 100% !important;
  text-align: left !important;
}

.m-g-2 tr td {
  padding: 5px 0px;
  text-align: center;
}

/*   .m-g-2 tr td:nth-child(1){text-align: left;}*/
.btn-blue.p-small {
  padding: 5px 10px;
}

.btn {
  /* border-radius: 8px !IMPORTANT; */
}

.form-control[readonly] {
  background-color: #F2F2F2;
  border: 0px !important;
}

.grey-btn {
  height: 20px;
  width: 20px;
  text-align: center;
  background: #898989;
  border-radius: 50% !important;
  color: #FFF;
  line-height: 1 !important;
  border: 0 !important;
}

.grey-btn.arrow-btn img {
  vertical-align: unset;
}

.grey-btn:active {
  background-color: #898989 !important;
}

.grey-btn:hover {
  background: #008FFB;
  color: #FFF;
}

.fw {
  border-radius: 10px;
  background: #458FFF;
  color: #FFF;
  font-size: 16px;
  font-family: F37 Moon;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0.25px;
  padding: 10px 50px;
  text-transform: uppercase;
}

.btn-close {
  opacity: 1;
  /* color: #FF4560 !important; */
  /* background: transparent !important; */
  padding: 0 !important;
  font-weight: 600;
  font-size: 18px;
  margin-top: -35px !important;
  margin-right: -5px !important;
}

.red-text {
  color: #ffffff !important;
  background: #FF4560 !important;
}

.c-grey {
  color: #404040;
}


.inputnumbericonhide input::-webkit-outer-spin-button,
.inputnumbericonhide input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputnumbericonhide input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width:1600px) and (min-width: 1441px) {

  .f-40 {
    font-size: 20px;
  }

  .i img {
    height: 16px;
  }

  .f-16 {
    font-size: 13px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 15px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Poppins';
    margin-bottom: 8px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 12px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 405px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 10px !important;
  }

  .modal-content table td {
    font-size: 10px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .tooltip {
    font-size: 12px;
  }
}

@media (max-width:1440px) and (min-width: 1300px) {

  .f-40 {
    font-size: 20px;
  }

  .i img {
    height: 16px;
  }

  .f-16 {
    font-size: 13px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 15px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    margin: 6px 2px !important;
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Poppins';
    margin-bottom: 8px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 12px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 405px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 10px !important;
  }

  .modal-content table td {
    font-size: 10px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .tooltip {
    font-size: 12px;
  }
}

@media (max-width:1299px) and (min-width: 1100px) {
  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 13px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    margin: 6px 2px !important;
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Poppins';
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 480px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 12px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }
}

@media (min-width: 1024px) {
  .row.gx-2.mt-2.db-grid .col-md-3 {
    width: 20%;
  }
}

.db-grid strong.f-12 {
  position: relative;
  z-index: 9;
}

.tooltip {
  position: static;
  display: inline-block;
  border-bottom: 0px;
  opacity: 1;
  padding: 0;
  cursor: alias;
}

.tooltip .tooltiptext {
  display: none;
  visibility: hidden;
  width: 120px;
  background-color: #dbdbdb;
  color: #000;
  text-align: center;
  border-radius: 4px;
  padding: 3px 6px;
  width: auto;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0px);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: block;
}

.tooltip.left-tool .tooltiptext {
  background-color: #dbdbdb;
  color: #000;

  bottom: 0%;
  left: 0;
  transform: translate(-102%, 0px);
}

.tooltip.left-tool.b-100 .tooltiptext {
  bottom: 100%;
  transform: translate(-95%, 0px);
}

.tooltip.right-tool .tooltiptext {
  right: 0;
  left: 15px;
  min-width: max-content;
  transform: translate(-30%, 0px);
}

.tooltip.big-data .tooltiptext {
  right: 0;
  left: 50%;
  min-width: max-content;
  max-width: 110px;
  transform: translate(-40%, 0px);
  white-space: normal !important;
  /* width: 150px; */
  min-width: 110px;
}

.modal-dialog.modal-dialog-centered.modal-lg {
  width: 100%;
  /* height: 105px; */
}

/* --------------------Added by sumit on 16may---------------------------------- */

@media (max-width:1152px) and (min-width: 1024px) {
  .tooltip {
    font-size: 12px;
  }

  .f-40 {
    font-size: 13px;
  }

  .i img {
    height: 14px;
  }

  .f-16 {
    font-size: 11px;
  }

  .f-14 {
    font-size: 11px;
  }

  .f-25 {
    font-size: 13px;
  }

  .shadow-box {
    padding: 7px 10px;
  }

  .my-custom {
    margin: 5px -10px !important;
  }

  .m-f14 {
    font-size: 13px;
  }

  .db-grid .shadow-box .col-md-5.text-end {
    padding-left: 0px;
  }

  .db-grid .shadow-box {
    font-size: 12px;
  }

  .nav-pills li button {
    margin: 6px 2px !important;
    font-size: 11px !important;
  }

  .nav-pills {
    margin-left: 0;
  }

  p {
    font: normal normal normal 12px/15px 'Poppins';
    margin-bottom: 10px;
  }

  .small-input .ui-input-text .form-control {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .f-13 {
    font-size: 12px !important;
  }

  .mt-3 {
    margin-top: 10px !important;
  }

  .db-grid {
    max-height: 480px;
    overflow: auto;
  }

  label.form-check-label.ui-btn {
    padding: 2px 10px;
    padding-left: 2.5em;
    font-size: 12px;
  }

  .date.f-12 {
    font-size: 9px !important;
  }

  table td {
    font-size: 11px !important;
  }

  .small-input .ui-input-text .form-control,
  .small-input .form-control,
  .form-control {
    font-size: 11px !important;
  }

  .nav-pills li button {
    padding: 8px 8px !important;
  }

  .hd th {
    font-size: 11px !important;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .col-md-7.ps-2 {
    width: 100%;
    padding-left: 0 !important;
    margin-top: 7px;
  }

  .col-4.h-100 {
    margin-top: 4px;
  }

  .av-btn {
    padding: 8px 6px;
  }

  .av-btn .f-14 {
    font-size: 12px;
  }

  .alert strong {
    font-size: 13px;
  }

  .alert {
    margin-top: 2px;
  }
}

@media (min-width: 576px) {
  .modal-sm.modal-dialog {
    max-width: 300px;
  }

  .w1 {
    width: 28%;
  }

  .w2 {
    width: 34%;
  }

  .w2+.col-md-4.border-start {
    width: 38%;
  }
}

td.img {
  position: relative;
}

td.img img {
  position: relative;
  z-index: 1;
}

/* td.img:after {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    background: #63BC62;
    right: 50%;
} */
span.circle-i {
  color: #63BC62;
  font-size: 12px;
  font-family: Sailec;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.52px;
  display: inline-block;
  height: 33px;
  width: 33px;
  border: 1px solid #63BC62;
  border-radius: 50%;
  background: #FFF;
  position: relative;
  z-index: 1;
}

.m-g-2 tr:nth-last-child(1) td.img:after,
.last-i:after {
  display: none;
}