h3{
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    }
    .widget-tabs{
        padding: 30px 20px;margin: 15px 0px;
    }
    .home-widget .widget-tabs { padding: 30px 20px 0;height: 100%; margin-bottom: 0px;}
    @media (max-width: 1900px){ .col-66{width: 25%;flex: auto;}}
    .ww1 img{width: 30px;}
    .home-widget .box-h:hover {background: transparent;}
    .ww1{min-width: 20%;}
    .home-widget .sp2{ width: 5% !important;min-width: auto;}
    @media (min-width: 1600px) and (max-width: 1800px){
        .ww1{min-width: 18%;}
        h3 {
            font: normal normal bold 1.6rem/2rem 'F37 Moon Demi';
        }
    }
    @media (min-width: 1100px) and (max-width: 1600px){
        .ww1{min-width: 20%;}
        h3 {
            font: normal normal bold 1.4rem/2rem 'F37 Moon Demi';
        }
    }