.modal-dialog.modal-md {
    max-width: 70%;
}
@media (max-width: 768px){
    .modal-dialog.modal-md {
        max-width: 90%;
    }
    .custom-font-size .ft-title.f-18.modal-title.h4 {
        font-size: 19px !important;
    }
    .ft-table-v.table th {
        font-size: 15px !important;
    }
    .big-font.f-18 {
        font-size: 18px !important;
        line-height: 1;
    }
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}
.max-w-620 .modal-dialog{max-width: 620px !important;}
.comment-form .form-control { width: 100%;
    height: auto;font-size: 14px !important;
}
.d-flex.inline-f{align-items: center;}
.d-flex.inline-f.mb-3 label {
    white-space: nowrap;
    width: 240px;
    display: block;
    text-align: right !important;
    padding-right: 15px;margin: 0;
}
.custom-cross-btn .btn-close {
    position: absolute;
    right: 20px;
    top: 50px;z-index: 1;
}
.max-w-900 .modal-dialog{max-width: 900px;}
.w-16{width: 16px;}
.spacing-table.comment-table td {
    padding: 10px 10px;
}
.transparent-bg{background-color: transparent;}
.comment-table-height{   max-height: 350px;
    display: block !important;
    overflow: auto;
    padding-right: 4px;
    min-height: 50px;}
    .spacing-table.comment-table thead, .spacing-table.comment-table tbody tr {
        display: table !important;
        width: 100%;
        /* table-layout: fixed; */
    }
    .spacing-table.comment-table th {
        padding: 0px 0px 5px;}
        .spacing-table.comment-table thead tr   {    display: table !important;
            width: calc(100% - 9px);}
            .border-color{border: 1px solid;}
            
.spacing-table.comment-table tr th:nth-child(1), .spacing-table.comment-table tr td:nth-child(1){width: 120px; max-width: 120px;}
.spacing-table.comment-table tr th:nth-child(2), .spacing-table.comment-table tr td:nth-child(2){width: 120px;max-width: 120px;}
.spacing-table.comment-table tr th:nth-child(3), .spacing-table.comment-table tr td:nth-child(3){width: 120px; max-width: 120px;}
.spacing-table.comment-table tr th:nth-child(4), .spacing-table.comment-table tr td:nth-child(4){width: 150px;max-width: 150px; min-width: 150px;}
.spacing-table.comment-table tr th:nth-child(5), .spacing-table.comment-table tr td:nth-child(5){width: 100px; max-width: 100px; min-width: 100px;}
.spacing-table.comment-table tr th:nth-child(6), .spacing-table.comment-table tr td:nth-child(6){width: 110px;}
.spacing-table.comment-table tr th:nth-child(7), .spacing-table.comment-table tr td:nth-child(7){width: 130px;}
.spacing-table.comment-table tr th:nth-child(8), .spacing-table.comment-table tr td:nth-child(8){width: 170px; max-width: 170px; min-width: 170px;    word-break: break-word;}
.spacing-table.comment-table tr th:nth-child(9), .spacing-table.comment-table tr td:nth-child(9){width: 110px;max-width: 110px; min-width: 110px;}