.first-t td:nth-child(1), .first-t th:nth-child(1) {
    width: auto; 
}


.border-0{border: 0;}
.fix-column-width tr td:nth-child(1){width: 94px;}
.fix-column-width tr td:nth-child(2){width: 94px;}
.fix-column-width tr td:nth-child(3){width: 94px;}
.fix-column-width tr td:nth-child(4){width: 94px;}
.fix-column-width tr td:nth-child(5){width: 94px;}
.fix-column-width tr td:nth-child(6){width: 94px;}
.fix-column-width tr td:nth-child(7){width: 94px;}
.fix-column-width tr td:nth-child(8){width: 94px;}
.fix-column-width tr td:nth-child(9){width: 94px;}
.fix-column-width tr td:nth-child(10){width: 94px;}
.fix-column-width tr td:nth-child(11){width: 94px;}
.fix-column-width tr td:nth-child(12){width: 94px;}
.fix-column-width tr td:nth-child(13){width: 94px;}
.fix-column-width tr td:nth-child(14){width: 94px;}
.fix-column-width tr td:nth-child(15){width: 94px;}
.fix-column-width tr td:nth-child(16){width: 94px;}
.fix-column-width tr td:nth-child(17){width: 94px;}
.fix-column-width tr td:nth-child(18){width: 94px;}
/* .fix-column-width tr td{min-width: 93px;}
.fix-column-width tr.w-93 th{min-width: 93px;} */
.scroll-table tbody::-webkit-scrollbar {
    width: 5px;
    width: 4px;
    height: 0px;
}

.scroll-table tbody.h-460 {

    max-height: calc(100vh - 410px);
    min-height: calc(100vh - 410px);

}
.biweeklytable td:nth-child(5), .biweeklytable th:nth-child(5) {

    /* width: 180px; */
}
.biweeklytable td:nth-child(1), .biweeklytable th:nth-child(1) {

    width: 380px; 
}

.disable-bi-link {
    color: #999;
    cursor: not-allowed;
}

.col-disable {
    background-color: #f5f5f5;
    box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
    border-radius: 5px;
    pointer-events: none;
}