 .modal-dialog.modal-md {
    max-width: 70%; 
}
@media (max-width: 768px){
    .modal-dialog.modal-md {
        max-width: 90%;
    }
    .custom-font-size .ft-title.f-18.modal-title.h4 {
        font-size: 19px !important;
    }
    .ft-table-v.table th {
        font-size: 15px !important;
    }
    .big-font.f-18 {
        font-size: 18px !important;
        line-height: 1;
    }
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}
.max-w-620 .modal-dialog{max-width: 620px !important;}
.comment-form .form-control { width: 100%;
    height: auto;font-size: 14px !important;
}
.d-flex.inline-f{align-items: center;}
.d-flex.inline-f.mb-3 label {
    white-space: nowrap;
    width: 240px;
    display: block;
    text-align: right !important;
    padding-right: 15px;margin: 0;
}
.custom-cross-btn .btn-close {
    position: absolute;
    right: 20px;
    top: 50px;z-index: 1;
}
.max-w-900 .modal-dialog{max-width: 900px;}
.mt-minus-20{margin-top: -20px;}
.border-bottom-none{border: 0px !important;}  
.th-border-0 th{border: 0px !important;}
.custom-width-table tr th:nth-child(1), .custom-width-table tr td:nth-child(1){
    width: 110px;
}
.custom-width-table tr th:nth-child(3), .custom-width-table tr td:nth-child(3){
    width: 110px;
}
.custom-width-table tr th:nth-child(5), .custom-width-table tr td:nth-child(5){
    width: 150px;
}
.table-bordered.custom-border>:not(caption)>* {
    border-width: 1px 0 0px;
}
.table-bordered.custom-border thead tr{border-bottom-width: 1px;}
.table-bordered.custom-border tbody.ftr tr{border-top-width: 1px;}
.table-bordered.custom-border tbody.crossi tr:nth-child(1){border-top-width: 0px;}
.custom-font-size h4.f-22 {
    font-size: 28px; margin-bottom: 5px;
}


 .tooltip {
     z-index: 9999 !important;
 }