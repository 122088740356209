.apexcharts-legend-series {
    display: inline-flex !important;
    width: auto !important;
}
.qus-ans-table tr th:nth-child(1){width: 90px;}
.qus-ans-table tr td:nth-child(1){width: 90px;}
.qus-ans-table tr th{font-size: 12px;text-align: left !important;}
.qus-ans-table td {
    padding: 7px 5px;
    vertical-align: top;font-size: 12px;
}
.qus-ans-table tbody {
    max-height: calc(30vh - 40px);
}
.bbd .border-btn {
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid;
    cursor: pointer;
    margin: 5px;

    
    /* background-color: #28A745 !important; */
    /* padding: 0px 15px !important; */
    border-radius: 8px !important;
    font-size: 13px !important;
    height: 35px !important;
    line-height: 35px !important;
    /* border: 0; */
    /* color: #FFF; */
    min-width: 100px;
    margin: 10px;
}
@media (max-width: 767px){
    .custom-select-v.mobie-full {
        width: 100%;text-align: center;
    }
}


.h-vt {
    min-height: calc(100vh - 205px);
    height: calc(100vh - 205px);
}