.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}
.form-select, .col .form-control {
    padding: 5.5px 12px;
    font-size: 12px !important;
    background-color: transparent !important;
    border-radius: 5px;
    border: 1px solid #828282 !important;
    /* box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50); */
    color: #4B4D4D;
    height: auto;
}
.form-label {
    font-size: 15px;
}
.dropdownwithsearch{position: relative;}
.searchlistwrap{display: none; border: 1px solid #ccc; position: absolute; top: 100%; background: #fff; z-index: 99; width: 100%;}
.searchlistwrap.showing{display: block;}
.searchlistwrap ul{list-style: none; padding-left: 0; font-size: 12px; max-height: 270px; overflow: auto; margin-bottom: 0;}
.searchlistwrap ul li{padding: 8px 12px; cursor: default;}
.searchlistwrap ul li:hover{background: #deebff;}
.searchlistwrap ul li.active{background-color: #2684ff; color: #fff;}
.searchlistwrap .searchfield{margin: 6px; display: flex; border:1px solid #f3f3f3; border-radius: 7px; align-items: center; background: #eee;}
.searchlistwrap .searchfield .searchicon{padding-left: 10px; display: block;}
.searchlistwrap .searchfield .form-control{border:0 !important; outline: 0; box-shadow: none;}
.dropdownwithsearch .input-group .form-control{
    border-right: 0 !important;
    cursor: default;
}
.btn-right{
    background: none;
    padding: 0;
    border-left: 0;
    border-radius: 0 6px 6px 0 !important;
    border-color: #828282;
}
.btn-right:hover, .btn-right:focus, .btn-right:active{background: none !important;border-color: #828282;}
.btn-right .css-1xc3v61-indicatorContainer{padding: 6px;}
.btn-fm{
    height: 35px;
    font-size: 14px;
    margin-left: 15px;
    margin-left: 1rem!important;
    margin-top: 14px;
}
.circle{ 
    display: inline-block;
    border-radius: 50%;
}
.size-11{width: 10px;height: 10px;margin-left: 7px;} 
.red-circle{ background: #E60000;}
.yellow-circle{ background: #F6C040;}
.blue-circle{background: #2878BD;}
.fa-flag {color: #F67C6A;}
.cursor-pointer{cursor: pointer;}
.exceptions-table .tb2 td {
    padding: 7px 2px;
    vertical-align: middle;
}
.dat .react-datepicker-wrapper input {
    width: 100% !important;
    background: transparent;
    padding: 5.5px 12px;
    font-size: 14px !important;
    background-color: #eee !important;
    border-radius: 5px;
    border: 1px solid #828282 !important;
    box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);
    color: #4B4D4D;
    height: 35px;
}
.scroll-table.small-scroll tbody{
    /* max-height: 270px; */
    max-height: calc(50vh - 251px) !important;

}
.min-627 {
    min-height: calc(100vh - 300px);
}
.min-665 {
    min-height: calc(100vh - 265px);
}
.scroll-table.long-table tbody.h-450 {
    max-height: calc(100vh - 460px);
}
.scroll-table.long-table tbody.h-530 {
    max-height: calc(100vh - 380px);
}
.dp{display: flex; align-items: center;}
.dp img{filter: invert(1);}
.dp:hover img{filter: invert(0);}
.redt{ font-size: 14px;  font-weight: 400;}
.yellowt{   font-size: 14px;  font-weight: 400;}
.bluet{  font-size: 14px;  font-weight: 400;}
.purplet{  font-size: 14px;  font-weight: 400;}
.greent{  font-size: 14px;  font-weight: 400;}
.oranget{  font-size: 14px;  font-weight: 400;}
.whitet{  font-size: 14px;  font-weight: 400;}
.circle { height: 12px; width: 12px;border-radius: 50%;margin-right: 5px;}
.redt .circle{background-color: rgb(232, 2, 8)}
.yellowt .circle{background-color:rgb(246, 193, 64)}
.bluet .circle{background-color:rgb(111, 176, 224);}
.purplet .circle{background-color:rgb(87, 60, 250);}
.greent .circle{background-color:rgb(31, 114, 8);}
.oranget .circle{background-color: rgb(241, 71, 39);}
.whitet .circle{background-color: rgb(255, 255, 255);}
.bxh .legend-text + .legend-text{margin-left: 20px;}
.mt-6px{margin-top: 10px !important;}   
.custom-with-c th:nth-child(2), .custom-with-c th:nth-child(3){width: 90px;}
.custom-with-c th:nth-child(4){width: 110px;}
.custom-with-c td:nth-child(2), .custom-with-c td:nth-child(3){width: 90px !important;}
.custom-with-c td:nth-child(4){width: 110px !important;}
.ww2 .border-btn { padding: 6px 10px;}
.ww1.col-md-3 { width: 27%;}
.ww2.col-md-2 {  width: 210px; }
.w-70{width: 70px;}
.w-58{width: 54%;}
/* .w-58 .cl1, .w-58 .cl2 { flex: 0 0 auto; width: 150px; } */
@media (max-width:3000px) and (min-width: 1650px)
{
     .w-58 .cl2 { flex: 0 0 auto; width: 150px; }
}
.yellobox {
    background-color: #e1b30e;
    padding: 1px 4px;
}
.redbox {
    background-color: #f24726;
    padding: 1px 4px;
}
.greybox {
    background-color: #a1a1a1;
    padding: 1px 4px;
}
.bluebox {
    background-color: #2d9bf0;
    padding: 1px 4px;
}
.w-45td td span{min-width: 45px;display: inline-block;}
/* .big-table{min-width: 1600px;} */
.overtime-details-table tr th:nth-child(2),
.overtime-details-table tr td:nth-child(2),
.overtime-details-table tr th:nth-child(3),
.overtime-details-table tr td:nth-child(3),
.overtime-details-table tr th:nth-child(4),
.overtime-details-table tr td:nth-child(4),
.overtime-details-table tr th:nth-child(5),
.overtime-details-table tr td:nth-child(5),
.overtime-details-table tr th:nth-child(6),
.overtime-details-table tr td:nth-child(6),
.overtime-details-table tr th:nth-child(7),
.overtime-details-table tr td:nth-child(7),
.overtime-details-table tr th:nth-child(8),
.overtime-details-table tr td:nth-child(8),
.overtime-details-table tr th:nth-child(9),
.overtime-details-table tr td:nth-child(9),
.overtime-details-table tr th:nth-child(10),
.overtime-details-table tr td:nth-child(10),
.overtime-details-table tr th:nth-child(11),
.overtime-details-table tr td:nth-child(11),
.overtime-details-table tr th:nth-child(12),
.overtime-details-table tr td:nth-child(12),
.overtime-details-table tr th:nth-child(13),
.overtime-details-table tr td:nth-child(13),
.overtime-details-table tr th:nth-child(14),
.overtime-details-table tr td:nth-child(14),
.overtime-details-table tr th:nth-child(15),
.overtime-details-table tr td:nth-child(15),
.overtime-details-table tr th:nth-child(16),
.overtime-details-table tr td:nth-child(16)
{
    width: 55px; max-width: 55px;
}
.overtime-details-table tr th:nth-child(17),
.overtime-details-table tr td:nth-child(17){
    width: 100px; max-width: 100px;
}
.overtime-details-table tr th:nth-child(18),
.overtime-details-table tr td:nth-child(18){
    width: 90px; max-width: 90px;
}
.overtime-details-table tr th:nth-child(1),
.overtime-details-table tr td:nth-child(1){
     max-width: 230px; width: 230px;
}
.custom-cross {
    position: absolute;
    right: 28px;
    top: 1px;
    font-size: 20px;
}
.lt span.css-udadfb-ButtonBase, .lt .css-178ag6o {
    padding-right: 15px;
}
.lt svg{margin-top: -3px;}