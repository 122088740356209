.modal-dialog.modal-md {
    max-width: 70%;
}
@media (max-width: 768px){
    .modal-dialog.modal-md {
        max-width: 90%;
    }
    .custom-font-size .ft-title.f-18.modal-title.h4 {
        font-size: 19px !important;
    }
    .ft-table-v.table th {
        font-size: 15px !important;
    }
    .big-font.f-18 {
        font-size: 18px !important;
        line-height: 1;
    }
}
.f-14.modal-body p{font-size: 14px; margin-bottom: 6px;}
.text-indent-15 p{margin-left: 25px;}
.text-indent-15 p.text-indent-30{margin-left: 45px;}
.max-w-620 .modal-dialog{max-width: 620px !important;}
/* .modal-content table td {
    padding: 15px 5px;
} */
button.btn-close {
    position: absolute;
    right: 25px;
    top: 50px;
}
.table-bordered>:not(caption)>* {
    border-width: 1px 1px;
}
.ans textarea{    min-height: 60px;}


.scroll-area.mt-4 {
    max-height: 460px;
    overflow: auto;    padding-right: 10px;
}
.scroll-area::-webkit-scrollbar {
    width: 5px;
    width: 4px;
    height: 0px;
}
.h200{    min-height: 80px !important; border-color: #3e3e3e !important;}
.f-24-custom{font-size: 24px;
    text-align: center;
    margin-bottom: 40px;}
    .qus{
        font-weight: 600 !important;
        font-size: 18px;
    }
    .ans p{
        font-weight: 100 !important;
        font-size: 14px;
    }
    .green-btn{
        background-color: #28A745 !important;
        padding: 0px 15px !important;
        border-radius: 8px !important;
        font-size: 13px !important;
        height: 35px !important;
        line-height: 35px !important;
        border: 0;
        color: #FFF;
        min-width: 100px; margin: 10px;
    }
.scroll-area::-webkit-scrollbar-thumb {
    background: #848689;
}

.scroll-area::-webkit-scrollbar-track {
    background: #DEEBFD;
    border-radius: 30px;
}




.w-950 .modal-dialog.modal-md {
    max-width: 950px;
}
.d-padding-0 tr td div{padding: 0; text-align: center;}
td.left-right-border {
    border-left: 1px solid;
    border-right: 1px solid;
}
.accordion-button{padding: 0; background-color: transparent !important;}
.accordion-item{padding: 0; background-color: transparent; border: 0;}
/* .accordion-button{color: #FFF;} */
.accordion-body {
    padding: 0;
}
.accordion-button:focus {
    box-shadow: none !important;}
    .accordion-item {
        border-bottom: 1px solid;
    }
    .accordion-button:not(.collapsed){box-shadow: none;}
    .bg-dark-custom{
        padding: 5px 20px;
    }
    .accordion-item:last-of-type{border-radius: 0px; border: 0px;}
    .custom-pad th{padding: 7px 2px; font-size: 14px;}
    .table-width tr th:nth-child(1), .table-width tr td:nth-child(1){width: 80px;}
    .table-width tr th:nth-child(2), .table-width tr td:nth-child(2){width: 60px;}
    .table-width tr th:nth-child(5), .table-width tr td:nth-child(5){width: 80px;}
    .table-width tr th:nth-child(6), .table-width tr td:nth-child(6){width: 80px;}
    .table-width tr th:nth-child(7), .table-width tr td:nth-child(7){width: 80px;}
    .modal-header .btn-close {
        z-index: 9;
    }
    .pb .accordion-button {
        line-height: 20px;
        padding: 10px 0px !important;
    }