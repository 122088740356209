.sidebar{     min-height: calc(100vh - 100px);height:100%;  display: flex;flex-direction: column;transition: all 0.2s linear;background: #FFF;padding: 10px 0px 10px 0px;box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.50);}
.dashboard-wrapper{display: flex; flex-direction: row;position: relative;}
.toplogo{height: 50px;}
.toplogo img{height: 40px;}
.sidenav {overflow: hidden;}
.sidebar ul{padding: 0;list-style: none;}
.sidebar ul li{padding: 2px 0px;font-size: 14px; font-weight: 400;line-height: 18px;  color: #000;}

.sidebar ul li a{color:#000; text-decoration: none;display: block;width: 100%; position: relative;}
.sidebar.sidebar--open{width: 300px;}
.sidebar.sidebar--open ul li span{display: inline-block;}
.sidebar .mainlogo{display: none;}
.sidebar .samllogo{display: block;}
.sidebar.sidebar--open .mainlogo{display: block;}
.sidebar.sidebar--open .samllogo{display: none;}

.sidebar--close ul li a span {
    margin-top: 6px;
    font-size: 14px;
    line-height: 15px;
}
/* .sidebar:hover{width: 300px;}  */
/* .sidebar:hover ul li span{display: inline-block;}
.sidebar:hover .mainlogo{display: block;}
.sidebar:hover .samllogo{display: none;} */
.sidebar ul li a.active::after{content: "";position: absolute;right: 0px;height: 100%; width: 5px;background: rgb(2, 109, 64);top:0;}